import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useCommonStore from 'store/common';
import { extractParams } from 'util/params/join/extract/extractParams';
import useDomainStore from 'store/domain';
import useClientStore from 'store/client';
import useUsageMetric from './useUsageMetric';

/**
 * 기기별 사용량을 수집한다.
 */
const useMobileUsage = () => {
  // store
  const commonStore = useCommonStore();
  const domainStore = useDomainStore();
  const clientStore = useClientStore();

  // hook
  const statisticManager = useUsageMetric();
  const location = useLocation();

  // var
  const [pointIdParam, setPointIdParam] = useState<string | undefined>('');
  const machineIdParam: string | undefined = extractParams(location.search, 'machineId');

  useEffect(() => {
    if (domainStore.domainType === 'WEB') {
      if (clientStore.client) {
        setPointIdParam(clientStore?.client?.id);
      }
    } else {
      const pointId = extractParams(location.search, 'pointId');
      setPointIdParam(pointId);
    }
  }, []);

  /**
   * 기기 사용량을 수집한다.
   */
  const postUsageStats = async () => {
    if (!pointIdParam) {
      return;
    }

    // 기기 사용량 수집
    await statisticManager.postUsages(pointIdParam, machineIdParam);
  };

  useEffect(() => {
    if (commonStore.appUuid && pointIdParam) {
      postUsageStats();
    }
  }, [clientStore.client, pointIdParam, commonStore.appUuid]);
};
export default useMobileUsage;
