import { MOBILE_SHARE_DEFAULT } from 'data/defaultImages';
import { useLocation } from 'react-router-dom';
import useLanguageStore from 'store/languages';
import useMachineStore from 'store/machine';
import useShareModalStore from 'store/shareModal';
import useTenantStore from 'store/tenant';
import { errorConsole } from 'util/common/console';
import { extractParams } from 'util/params/join/extract/extractParams';

const useShareButton = () => {
  // store
  const tenantStore = useTenantStore();
  const shareModalStore = useShareModalStore();
  const langStore = useLanguageStore();
  const machineStore = useMachineStore();

  // hook
  const location = useLocation();

  /**
   * 공유하기 버튼 클릭 이벤트 핸들러
   * @function onClickShare
   * @returns {void}
   */
  const onClickShare = (): void => {
    if (!tenantStore.currentTenant) {
      return errorConsole('tenant가 없습니다');
    }

    // 공유하기 팝업 열기
    shareModalStore.setOpenSharePopup(true);

    const pointId: string | undefined = extractParams(location.search, 'pointId');
    const machineId: string | undefined = extractParams(location.search, 'machineId');
    const url = `${process.env.REACT_APP_URL}/?type=location&pointId=${pointId}&machineId=${machineId}&destTenantId=${tenantStore.currentTenant.id}`;
    const boothName = tenantStore.currentTenant.name[langStore.currentLang];

    /**
     * 공유할 URL을 설정한다.
     * clientPath 가 있을 때는 전시 URL로 설정
     */
    if (machineStore.machine?.workspace.clientPath) {
      shareModalStore.setShareUrl(
        `${process.env.REACT_APP_URL}/exh/${machineStore.machine?.workspace.clientPath}/${tenantStore.currentTenant.boothNumber}`,
      );
    } else {
      shareModalStore.setShareUrl(url);
    }

    // 카카오 공유 템플릿 생성 시 필요한 정보 저장
    shareModalStore.setKakaShare({
      title: boothName,
      // description: `${machineStore.machine?.workspace.clientPath} ${boothName} 부스에 초대합니다.`,
      // TODO: 1031 cafe show 기간동안 사용하도록 하드 코딩
      description: ` ${
        machineStore.machine?.workspace.clientPath === 'cafeshow'
          ? '[서울 카페쇼]'
          : machineStore.machine?.workspace.clientPath
      } ${boothName} 부스에 초대합니다.`,
      imageUrl: tenantStore.currentTenant.subLogoUri || MOBILE_SHARE_DEFAULT,
      buttonText: '부스 정보 보기',
    });

    // 이미지 다운로드 시 파일명 지정
    shareModalStore.setQrFileTitle('부스정보');
  };

  return { onClickShare };
};
export default useShareButton;
