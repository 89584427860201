export const EndPoint = {
  clientPath: '/mobile/v2/points/client-path',
  machinesV2: '/mobile/v2/machines',
  pointsV2: '/mobile/v2/points',

  languages: '/mobile/languages',
  floors: '/mobile/floors',
  tenants: '/mobile/tenants',
  tenantCategoryGroups: '/mobile/tenant-category-groups',
  screensavers: '/mobile/v2/screensavers',
  mainMenuV2: '/v2/mobile/main-menus',
  workspace: '/mobile/workspaces/:workspaceId',
  customPage: '/mobile/custom-pages/:customPageId',
  customFields: '/mobile/poi-content-custom-fields/:poi-content-id',

  // 키워드 검색
  searchKeyword: '/mobile/poi-content-keywords',
  booth: '/mobile/booths/:boothId',

  // 통계
  usagesV2: '/v2/mobile/metrics/usages', // 기기 사용량 v2 (uuid)
  tenantsUsage: '/mobile/metrics/tenants', // 매장 사용량
  categoryGroupsUsage: '/mobile/metrics/tenant-category-groups', // 카테고리 사용량
  deviceMotionAttachedV2: '/v2/mobile/metrics/usages/attach', // 기기 모션 접근 허용 여부 v2 (uuid)
  approach: '/mobile/metrics/approach', // 진입 타입
};
