import { Marker } from 'types/map/marker.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { focusToPoi } from './camera';
import { clearMap } from './common/clear';
import { drawMarkers } from './marker';
import { findObject } from './object';
import { findPoi } from './poi';

/**
 * 편의시설 마커를 그린다.
 * @param {TenantWithPointId[]} tenants 선택한 테넌트 목록
 * @param {string} currentFloorId 현재 층 아이디
 * @returns {Promise<Marker[] | undefined>} 현재 층에 존재하는 편의시설 마커
 */
export const drawFacilityMarkers = async (
  tenants: TenantWithPointId[],
  currentFloorId: string,
): Promise<Marker[] | undefined> => {
  clearMap();

  const currentFloorTenants = tenants.filter(tenant => tenant.content.floorId === currentFloorId);

  // 현재 층의 마커가 없다면 return
  if (!currentFloorTenants || currentFloorTenants.length < 1) return undefined;

  const currentFloorMarkers = tenants.map((tenant: TenantWithPointId) => {
    let position = { x: 0, y: 0 };

    if (tenant.content.objectId) {
      const object = findObject({ id: tenant.content.objectId });
      if (object) {
        position = object.position;
      }
    } else {
      const poi = findPoi({ id: tenant.content.poiId });
      if (poi) {
        position = poi.position;
      }
    }

    return {
      x: Number(position.x),
      y: Number(position.y),
      iconOption: {
        positionZ: 50,
        iconUrl: '/assets/icon/arrive.png',
        width: 50,
        height: 70,
        anchor: {
          x: 0.5,
          y: -0.1,
        },
      },
      floorId: tenant.content.floorId,
      // poiId: tenant.content.poiId,
    };
  });

  focusToPoi(currentFloorMarkers);
  await drawMarkers(currentFloorMarkers);

  return currentFloorMarkers;
};
