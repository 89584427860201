import CLOSE_BLACK from 'assets/icon/menu/close.svg';
import SEARCH from 'assets/icon/menu/search.svg';
import CHECK_WHITE from 'assets/icon/check-white.svg';
import GO_UP from 'assets/icon/go-up.svg';
import ARROW_UP_GRAY from 'assets/icon/arrow-up-gray.svg';
import ARROW_DOWN_BLACK from 'assets/icon/arrow-down-black.svg';
import STAR_EMPTY from 'assets/icon/star-empty.svg';
import STAR from 'assets/icon/star.svg';
import SHARE from 'assets/icon/share.svg';
import COPY_LINK from 'assets/icon/share/copy_link.svg';
import KAKAO_SHARE from 'assets/icon/share/kakao_share.svg';
import QR_DOWNLOAD from 'assets/icon/share/qr_download.svg';
import CLOSE_BTN from 'assets/icon/close_btn.svg';
import MAP from 'assets/icon/map.svg';
import BOOKMARK from 'assets/icon/bookmark.svg';
import BOOKMARK_MENU from 'assets/icon/menu/bookmark.svg';
import ARROW_RIGHT from 'assets/icon/arrow-right.svg';
import GLOBE from 'assets/icon/globe.svg';

export const ICONS = {
  CLOSE_BLACK,
  SEARCH,
  CHECK_WHITE,
  GO_UP,

  ARROW_UP_GRAY,
  ARROW_DOWN_BLACK,
  ARROW_RIGHT,

  STAR_EMPTY,
  STAR,

  SHARE,
  COPY_LINK,
  KAKAO_SHARE,
  QR_DOWNLOAD,

  CLOSE_BTN,

  MAP,
  BOOKMARK,
  BOOKMARK_MENU,

  GLOBE,
};
