import KIO from 'assets/error/kio.svg';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useLoadingStore from 'store/loading';
import { PathType } from 'types/common/path.type';
import { joinParams } from 'util/params/join/joinParams';
import useDomainStore from 'store/domain';
import styles from './Pages.module.scss';

const ErrorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientPath } = useParams();
  const domainStore = useDomainStore();
  const loadingStore = useLoadingStore();

  const clickRefresh = () => {
    if (domainStore.domainType === 'WEB') {
      navigate(`/web/${clientPath}}`);
    }
    if (domainStore.domainType === 'MOBILE') {
      navigate(`/${PathType.main}?${joinParams(location.search)}`);
    }
    window.location.reload();
  };

  useEffect(() => {
    loadingStore.setInitialLoading(false);
    loadingStore.setHasMapData(false);
  }, []);

  return (
    <div className={styles.error}>
      <img className={styles.kio_icon} src={KIO} alt='kiosk' />

      <button onClick={clickRefresh} className={styles.refresh_btn} type='button'>
        다시 시도하기
      </button>
    </div>
  );
};
export default ErrorPage;
