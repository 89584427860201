import { ICONS } from 'constants/icons';
import { LOCAL_STORAGE_BOOKMARK_KEY } from 'data/localStorageKey';
import { t } from 'i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PathType } from 'types/common/path.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { joinParams } from 'util/params/join/joinParams';
import useDomainStore from 'store/domain';
import styles from './MenuMoreInfo.module.scss';

const MyBookmark = () => {
  // hook
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = joinParams(location.search);
  const { clientPath } = useParams(); // 동적 파라미터 읽기

  // store
  const domainStore = useDomainStore();

  // var
  const storageBookmark = localStorage.getItem(LOCAL_STORAGE_BOOKMARK_KEY);
  const myBookmarks: TenantWithPointId[] = storageBookmark ? JSON.parse(storageBookmark || '') : [];

  /**
   * 즐겨찾기 클릭하면 즐겨찾기 페이지로 이동한다
   */
  const onClick = () => {
    if (myBookmarks.length > 0) {
      // domain type 에 따른 분기
      if (domainStore.domainType === 'WEB') {
        navigate(`/web/${clientPath}/${PathType.bookmark}`);
      }
      if (domainStore.domainType === 'MOBILE') {
        navigate(`/${PathType.bookmark}?${queryParams}`);
      }
    }
  };

  return (
    <div onClick={onClick} className={styles.wrapper}>
      <div className={styles.title}>{t('bookmark.bookmark')}</div>

      <div className={styles.info_wrapper}>
        {myBookmarks.length > 0 ? (
          <>
            <div className={styles.content}>{t('bookmark.count', { count: myBookmarks.length })}</div>

            <div>
              <img src={ICONS.ARROW_RIGHT} alt='더보기 아이콘' />
            </div>
          </>
        ) : (
          <div className={styles.empty}>추가된 목록이 없습니다.</div>
        )}
      </div>
    </div>
  );
};
export default MyBookmark;
