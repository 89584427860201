import DownSvg from 'components/common/icon/arrow/down/DownSvg';
import { t } from 'i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { joinParams } from 'util/params/join/joinParams';
import useDomainStore from 'store/domain';
import styles from './CloseButton.module.scss';

type Props = {
  closePopup: () => void;
};

const CloseButton = ({ closePopup }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const domainStore = useDomainStore();

  const { clientPath } = useParams();

  const handleClickCloseBtn = () => {
    closePopup();
    if (domainStore.domainType === 'WEB') {
      const parts = location.pathname.split('/').filter(Boolean);
      const path = parts[parts.length - 1];
      navigate(`/web/${clientPath}/${path}`);
    }
    if (domainStore.domainType === 'MOBILE') {
      navigate(`${location.pathname}?${joinParams(location.search)}`);
    }
  };

  return (
    <div className={styles.close_btn} onClick={handleClickCloseBtn}>
      <div className={styles.close_text}>{t(`button.close`)}</div>

      <DownSvg color='#222' />
    </div>
  );
};
export default CloseButton;
