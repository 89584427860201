import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import useClientStore from 'store/client';
import { ApiResponse } from 'types/api/response';
import { Client } from 'types/client/client.type';
import { errorConsole } from 'util/common/console';

const useClient = () => {
  const { api } = useAxios();
  const clientStore = useClientStore();

  const getClient = async ({ clientPath }: { clientPath: string }) => {
    try {
      const response = await api.get<ApiResponse<Client>>(EndPoint.clientPath, {
        params: { clientPath },
      });

      if (response.data) {
        clientStore.setClient(response.data);
        return response.data;
      }
    } catch (error) {
      errorConsole('clinet path 를 불러올 수 없습니다.');
    }
  };

  return {
    getClient,
  };
};
export default useClient;
