import { Client } from 'types/client/client.type';
import { create } from 'zustand';

type ClientStore = {
  client: Client | null;
  setClient: (client: Client) => void;
};

const useClientStore = create<ClientStore>((set, get) => ({
  client: null,
  setClient(client: Client) {
    set({
      client,
    });
  },
}));

export default useClientStore;
