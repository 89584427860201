import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import { ApiResponse } from 'types/api/response';
import { Booth } from 'types/booth/booth.type';

const useBooth = () => {
  // hook
  const apiManager = useAxios();

  /**
   * @desc 부스를 조회한다.
   * @param {string} exhibitionTitle 부스명
   */
  const getBooth = async (exhibitionTitle: string, boothNumber: string) => {
    const response = await apiManager.api.get<ApiResponse<Booth>>(EndPoint.booth.replace(':boothId', boothNumber), {
      params: {
        clientPath: exhibitionTitle,
      },
    });

    return response.data;
  };

  return { getBooth };
};
export default useBooth;
