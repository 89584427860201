import styles from './DetailTenantPopup.module.scss';
import DetailTenantPopupBody from './body/DetailTenantPopupBody';
import DetailTenantPopupButtons from './buttons/DetailPopupButtons';
import DetailTenantPopupHeader from './header/DetailTenantPopupHeader';

type DetailTenantPopupProps = {
  isOpenPopup?: boolean;
  closeModal: () => void;
  closeWithButton?: () => void;
};

const DetailTenantPopup = ({ closeWithButton, closeModal, isOpenPopup }: DetailTenantPopupProps) => {
  return (
    <div className={styles.background}>
      <div className={`${styles.wrapper} ${isOpenPopup ? styles.open : styles.close}`}>
        <div className={styles.popup_wrapper}>
          <DetailTenantPopupHeader closeModal={closeWithButton ?? closeModal} />
          <DetailTenantPopupBody />
          <DetailTenantPopupButtons closeModal={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default DetailTenantPopup;
