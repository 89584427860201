import { Content, Keyword } from 'types/tenant/keyword.type';
import { sortByLangOrder } from 'util/sort/lang-order/sortByLangOrder';
import { create } from 'zustand';

type ContentObject = {
  [key: string]: Content;
};

type KeywordStore = {
  keyword: Keyword;
  setKeyword: (keyword: Keyword) => void;
  clearInput: () => void;

  content: ContentObject | null;
  setKeywordOfContent: (content: ContentObject | null) => void;

  filterContent: string[];
  setFilterKeyword: (content: ContentObject | null, text: string) => void;
};

const useKeywordStore = create<KeywordStore>((set, get) => ({
  keyword: { text: '', type: 'input' },
  setKeyword(keyword: Keyword) {
    set({ keyword });
  },

  clearInput() {
    const clear: Keyword = { text: '', type: 'input' };
    set({ keyword: clear });
  },

  content: null,
  setKeywordOfContent(content: ContentObject | null) {
    set({ content });
  },

  filterContent: [],
  setFilterKeyword(content: ContentObject | null, text: string) {
    // text를 대문자로 변환 후 비교한다.
    const convertText = text.toLocaleUpperCase();

    if (content === null || text === '') {
      set({ filterContent: [] });
      return;
    }

    const keywords = Object.keys(content);
    const setFilterKeywords = keywords.filter(key => key.includes(convertText)); // 일치하는 Keys

    // 오름차순
    const sortedFilterKeywords = setFilterKeywords.sort((a, b) => sortByLangOrder(a, b));

    // filterkeyword가 하나일때 text가 일치하면 비워준다.
    if (setFilterKeywords.length === 1 && setFilterKeywords[0] === convertText) {
      set({ filterContent: [] });
      return;
    }
    set({ filterContent: sortedFilterKeywords });
  },
}));

export default useKeywordStore;
