/* eslint-disable prefer-destructuring */
import HERE from 'assets/floor/here.svg';
import { ME_DEFAULT } from 'data/image';
import useFloor from 'hooks/api/floor/useFloor';
import useMapDirection from 'hooks/map/trigger/useMapDirection';
import MapDraw from 'map/MapDraw';
import { addBuilding } from 'map/control/building';
import { changeCamera, moveCamera } from 'map/control/camera';
import { clearMap } from 'map/control/common/clear';
import { changeFloor } from 'map/control/floor';
import { showMyLocationNoAni } from 'map/control/myLocation';
import { changeMapMinMaxZoom } from 'map/control/zoom';
import useCategoryGroupStore from 'store/categoryGroup';
import useColorSetStore from 'store/colorSet';
import useCommonStore from 'store/common';
import useFacilityStore from 'store/facility';
import useFloorStore from 'store/floor';
import useLoadingStore from 'store/loading';
import usePointStore from 'store/point';
import usePopupStore from 'store/popup';
import useTrackingStore from 'store/tracking';
import { CategoryFeature, CategoryGroupWithTenants } from 'types/category/category.type';
import { Floor } from 'types/floor/floor.type';
import { Point } from 'types/point/point.type';
import styles from '../FloorList.module.scss';

type Props = {
  setClickFloorBtn: (value: boolean) => void;
};

const MyLocationBtn = ({ setClickFloorBtn }: Props) => {
  // store
  const floorStore = useFloorStore();
  const facilityStore = useFacilityStore();
  const loadingStore = useLoadingStore();
  const popupStore = usePopupStore();
  const themeStore = useColorSetStore();
  const pointStore = usePointStore();
  const categoryStore = useCategoryGroupStore();
  const trackingStore = useTrackingStore();
  const commonStore = useCommonStore();

  // hook
  const { findFloorNameByBuildingId } = useFloor();
  const { switchActionByTrackingType } = useMapDirection();

  // 층 이름 변경
  const changeFloorName = (floorId: string, buildingId: string) => {
    const floorName = findFloorNameByBuildingId(floorId, buildingId);

    if (floorName) {
      floorStore.setFloorName(floorName);
    }
  };

  // 층 목록 변경
  const changeFloors = (pointId: string) => {
    const findFloors: Floor[] | undefined = floorStore.pointFloorsMap?.get(pointId);

    if (findFloors) {
      floorStore.setFloors(findFloors);
    }
  };

  // 선택된 point 변경
  const changePoint = (pointId: string) => {
    const findPoint: Point | undefined = pointStore.pointsMap?.get(pointId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);
    }
  };

  // 카테고리 리스트 변경
  const changeCategories = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList(CategoryFeature.mapFacility, findCategories);
      }
    }
  };

  // 클릭 시
  const onClick = async () => {
    clearMap();

    loadingStore.setFloorChanging(true);

    setClickFloorBtn(false);

    facilityStore.resetCurrentFacilityId();

    popupStore.setOpenToastPopup(false);

    const machinePointId = MapDraw.originPointId;
    const machineFloorId = MapDraw.originFloorId;
    const machineBuildingId = MapDraw.originBuildingId;

    changeFloors(machinePointId);
    changePoint(machinePointId);
    changeCategories(machinePointId);

    /**
     * 지도의 빌딩 이동 및 층 이동
     *
     * 같은 빌딩이라면 changeFloor 해야 하고 다른 빌딩이라면 addBuilding 해야함.
     * 이유 : 2024 0404 기준 addBuilding 시 이미 context 에 같은 빌딩 있으면 api 내부적으로 return 하고 있음.
     * 추후 changeFloor 메서드로 다른 빌딩까지 이동할 수 있도록 api 업데이트 예정이라고 들음.
     */
    if (pointStore.selectedPoint?.id === machinePointId) {
      await changeFloor(machineFloorId);
    } else {
      await addBuilding(machineBuildingId, machineFloorId);
    }

    changeFloorName(machineFloorId, machineBuildingId);
    floorStore.setCurrentFloorId(machineFloorId);

    // 내위치 gps 그린다 (machineId 없을 경우 off 상태로 노출)
    if (commonStore.hasMachineId) {
      await switchActionByTrackingType(trackingStore.trackingType);
    } else {
      await showMyLocationNoAni(ME_DEFAULT);
    }

    // 기기 위치로 카메라 이동
    moveCamera(MapDraw.mobileMapPosition.coordinate);

    // 기기 포인트 최소/최대 줌레벨 적용
    changeMapMinMaxZoom(MapDraw.mobileMapPosition.mapMinZoom, MapDraw.mobileMapPosition.mapMaxZoom);

    // 기기 방향각, 줌레벨 적용
    changeCamera({
      rotation: MapDraw.mobileMapPosition.rotation,
      zoom: MapDraw.mobileMapPosition.zoom,
      transition: true,
    });

    loadingStore.setFloorChanging(false);
  };
  return (
    <button
      style={{ background: themeStore.sub }}
      disabled={loadingStore.isLoadingMap}
      onClick={onClick}
      type='button'
      className={styles.current_btn}
    >
      <img className={styles.location_icon} src={HERE} alt='here' />
    </button>
  );
};
export default MyLocationBtn;
