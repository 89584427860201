import ModalBottomSheetLayout from 'components/popup/layout/bottom-sheet/ModalBottomSheetLayout';
import { t } from 'i18next';
import { MutableRefObject, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useModalStore from 'store/modal';
import useNaviStore from 'store/navi';
import useTenantStore from 'store/tenant';
import useTrackingStore from 'store/tracking';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { extractParams } from 'util/params/join/extract/extractParams';
import { findTenant } from 'util/tenant/findTenant';
import SetMyParkingLocationButton from '../common/my-parking-location/SetMyParkingLocationButton';
import ParkingLocationInfo from '../common/parking-location-info/ParkingLocationInfo';
import styles from './SaveMyParkingLocationPopup.module.scss';

type Props = {
  isOpenPopup: boolean;
  onClosePopup: () => void;
  parkingPopupCloseTimer: MutableRefObject<NodeJS.Timeout | null>;
};

const SaveMyParkingLocationPopup = (props: Props) => {
  // store
  const tenantStore = useTenantStore();
  const modalStore = useModalStore();
  const naviStore = useNaviStore();
  const trackingStore = useTrackingStore();

  // state
  const [currentParkingLot, setCurrentParkingLot] = useState<TenantWithPointId>();

  // hook
  const location = useLocation();

  // 출발지
  const originTenantIdParam: string | undefined = extractParams(location.search, 'originTenantId');
  const tenantIdParam: string | undefined = extractParams(location.search, 'tenantId');

  // parking popup 으로 들어왔을 경우, tenantId 로 테넌트 찾기
  const findCurrentParkingLocation = (tenantId: string) => {
    if (tenantId) {
      const tenant = findTenant(tenantStore.rawTenants, tenantId);

      if (tenant) {
        setCurrentParkingLot(tenant);
      }
    }
  };

  /**
   * 내 주차위치 지정 팝업일 경우
   */
  // TODO: 기존 URL 정리 후 제거
  useLayoutEffect(() => {
    if (!modalStore.openMyParkingModal) {
      if (tenantIdParam) {
        findCurrentParkingLocation(tenantIdParam);
      }
      // 출발지
      else if (originTenantIdParam) {
        findCurrentParkingLocation(originTenantIdParam);
      }
    }
  }, [modalStore.openMyParkingModal, tenantStore.rawTenants, originTenantIdParam, tenantIdParam]);

  /**
   * 주차 기능일 경우 아이콘을 변경해준다.
   */
  useEffect(() => {
    naviStore.setNaviDestIconUrl('/assets/icon/arrive-green.png');
    naviStore.setNaviDestIconSize({ width: 37, height: 52 });
  }, []);

  if (!currentParkingLot) {
    return <> </>;
  }

  return (
    <ModalBottomSheetLayout
      disableOutside={trackingStore.isOpenPermissionAlert}
      outsideClose
      {...props}
      dim={false}
      zIndex={30}
    >
      <div>
        <div
          className={`${styles.popup} ${modalStore.openMyParkingModal && styles.padding} ${
            modalStore.openMyParkingModal && styles.gap
          }`}
        >
          <div className={`${styles.message_wrapper} ${!modalStore.openMyParkingModal && styles.padding}`}>
            <pre className={styles.message}>{t('popup.setMyParkingLocation')}</pre>

            <ParkingLocationInfo parkingLocation={currentParkingLot} />
          </div>
        </div>

        <div className={styles.main_btn_wrapper}>
          {/* 취소 */}
          <button className={`${styles.btn} ${styles.cancel_btn}`} onClick={props.onClosePopup} type='button'>
            <div className={styles.button_text}>{t('button.cancel')}</div>
          </button>

          {/* 주차위치 저장 */}
          <SetMyParkingLocationButton type='default' {...props} />
        </div>
      </div>
    </ModalBottomSheetLayout>
  );
};
export default SaveMyParkingLocationPopup;
