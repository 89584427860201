import { changeCamera, moveCamera } from 'map/control/camera';
import { changeMapMinMaxZoom } from 'map/control/zoom';
import MapDraw from 'map/MapDraw';
import { Point } from 'types/point/point.type';

/**
 * @desc 빌딩 변경 시 지도 센터값, 방향각, 줌레벨 그리고 최소/최대 줌레벨을 변경한다.
 * @param {Point} point 변경된 포인트
 *
 * ex) 기기의 빌딩인 경우 해당 기기의 줌레벨을 적용.
 *     다른 빌딩인 경우 포인트의 줌레벨을 적용.
 *
 *     최소/최대 줌레벨은 포인트의 값으로 적용.
 */
export const changeMapPointSetting = (point: Point) => {
  // 지도 센터값
  const cameraPosition =
    point.id === MapDraw.originPointId
      ? {
          x: MapDraw.mobileMapPosition.centerPositionX,
          y: MapDraw.mobileMapPosition.centerPositionY,
        }
      : {
          x: point.mobile.centerPositionX,
          y: point.mobile.centerPositionY,
        };

  moveCamera(cameraPosition);

  // 포인트별 지도 최소/최대 줌레벨 적용
  changeMapMinMaxZoom(Number(point.mobile.mapMinZoom), Number(point.mobile.mapMaxZoom));

  // 지도 방향각
  const rotation = point.id === MapDraw.originPointId ? MapDraw.mobileMapPosition.rotation : point.mobile.rotation;

  // 지도 기본 줌레벨
  const defaultZoom = point.id === MapDraw.originPointId ? MapDraw.mobileMapPosition.zoom : point.mobile.zoom;

  changeCamera({
    rotation: Number(rotation),
    zoom: Number(defaultZoom),
    transition: false,
  });
};

export const changeMapPointSettingWithoutCamera = (point: Point) => {
  // 포인트별 지도 최소/최대 줌레벨 적용
  changeMapMinMaxZoom(Number(point.mobile.mapMinZoom), Number(point.mobile.mapMaxZoom));

  // 지도 방향각
  const rotation = point.id === MapDraw.originPointId ? MapDraw.mobileMapPosition.rotation : point.mobile.rotation;

  // 지도 기본 줌레벨
  const defaultZoom = point.id === MapDraw.originPointId ? MapDraw.mobileMapPosition.zoom : point.mobile.zoom;

  changeCamera({
    rotation: Number(rotation),
    zoom: Number(defaultZoom),
    transition: false,
  });
};
