import GoUpButton from 'components/common/button/go-up/GoUpButton';
import NoTenant from 'components/common/no-tenant/NoTenant';
import DetailTenantPopup from 'components/popup/tenant/detail/DetailTenantPopup';
import SimpleTenantPopup from 'components/popup/tenant/simple/SimpleTenantPopup';
import useGoTopButton from 'hooks/go-to-top/useGoTopButton';
import { clearMap } from 'map/control/common/clear';
import { Fragment, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import useColorSetStore from 'store/colorSet';
import usePopupStore from 'store/popup';
import useTenantStore from 'store/tenant';
import useTrackingStore from 'store/tracking';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import styles from './BookmarkList.module.scss';
import BookmarkItem from './list/item/BookmarkItem';

type BookmarkListProps = {
  bookmarks: TenantWithPointId[];
  closeBookmarks?: () => void;
};

const BookmarkList = ({ bookmarks, closeBookmarks }: BookmarkListProps) => {
  // state
  const [openTenantPopup, setOpenTenantPopup] = useState(false);
  // ref
  const scrollRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  // store
  const tenantStore = useTenantStore();
  const popupStore = usePopupStore();
  const colorSetStore = useColorSetStore();
  const trackingStore = useTrackingStore();
  // hooks
  const { showGoButton } = useGoTopButton({ listRef });
  // variable
  const hasBookmarks = bookmarks && bookmarks.length > 0;

  /**
   * 즐겨찾기 목록 아이템 클릭 이벤트 핸들러
   * @function handleBookmarkItemClick
   * @param {TenantWithPointId} tenant 목록에서 선택한 tenant 정보
   * @returns {void}
   */
  const handleBookmarkItemClick = (tenant: TenantWithPointId): void => {
    popupStore.setOpenToastPopup(false);
    tenantStore.setCurrentTenant(tenant);
    setOpenTenantPopup(true);
  };

  /**
   * 닫기 버튼으로 모달을 닫는다.
   */
  const closeModalWithButton = () => {
    if (trackingStore.isOpenPermissionAlert) {
      return;
    }

    clearMap();
    setOpenTenantPopup(false);
  };

  /**
   * 모달과 즐겨찾기 목록을 같이 닫는다.
   */
  const closeModalAndBookmarkList = () => {
    if (trackingStore.isOpenPermissionAlert) {
      return;
    }

    if (closeBookmarks) {
      closeBookmarks();
    }

    clearMap();
    setOpenTenantPopup(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.list}
          ref={listRef}
          style={{
            background: colorSetStore.list.bg,
          }}
        >
          <div ref={scrollRef} />
          {bookmarks &&
            bookmarks.map((storageTenant: TenantWithPointId) => (
              <Fragment key={storageTenant.id}>
                <BookmarkItem handleClickItem={handleBookmarkItemClick} tenant={storageTenant} type='search' />
              </Fragment>
            ))}
          {!hasBookmarks && <NoTenant />}
        </div>
        {/* 위로가기 버튼 */}
        <GoUpButton scrollRef={scrollRef} isShow={showGoButton} overButton />
      </div>

      {tenantStore.currentTenant && (
        <Transition timeout={500} in={openTenantPopup} unmountOnExit>
          {tenantStore.currentTenant.popupType === 'SMALL' ? (
            <SimpleTenantPopup
              isOpenPopup={openTenantPopup}
              closeWithButton={closeModalWithButton}
              closeModal={closeModalAndBookmarkList}
            />
          ) : (
            <DetailTenantPopup
              isOpenPopup={openTenantPopup}
              closeWithButton={closeModalWithButton}
              closeModal={closeModalAndBookmarkList}
            />
          )}
        </Transition>
      )}
    </>
  );
};

export default BookmarkList;
