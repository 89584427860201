import { CustomField } from 'types/tenant/customField.type';
import { create } from 'zustand';

type CustomFieldStoreType = {
  customField: CustomField;
  setCustomField: (customField: CustomField) => void;
};

const useCustomFieldStore = create<CustomFieldStoreType>(set => ({
  customField: {
    phone: '',
    homePage: '',
    businessHours: '',
    classification: '',
    product: '',
    owner: '',
    address: '',
    email: '',
    snsLinkedIn: '',
    snsYoutube: '',
    snsFacebook: '',
    snsInstagram: '',
    snsKakao: '',
    snsBlog: '',
  },

  setCustomField(customField: CustomField) {
    set(() => ({
      customField,
    }));
  },
}));

export default useCustomFieldStore;
