import InfoGroup from 'components/common/info/group/basic/InfoGroup';
import useLanguageStore from 'store/languages';
import useTenantStore from 'store/tenant';
import styles from '../SimpleTenantPopup.module.scss';
import PopupInnerButtons from '../../detail/body/inner-buttons/PopupInnerButtons';

const SimpleTenantPopupBody = () => {
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();

  return (
    <div className={styles.popup_contents}>
      {/* 타이틀 */}
      <div className={styles.popup_title}>
        <div className={styles.title}>{tenantStore.currentTenant?.name[langStore.currentLang] ?? '-'}</div>
        <InfoGroup />
      </div>
      {/* inner 메뉴  */}
      <div className={styles.popup_image_wrapper}>
        <PopupInnerButtons />
      </div>
    </div>
  );
};
export default SimpleTenantPopupBody;
