import { DEFAULT_IMAGE } from 'data/defaultImages';
import { useEffect, useRef } from 'react';
import useLanguageStore from 'store/languages';
import useTenantStore from 'store/tenant';
import useCustomField from 'hooks/api/tenant/useCustomField';
import styles from './DetailTenantPopupBody.module.scss';
import PopupInnerButtons from './inner-buttons/PopupInnerButtons';
import DetailTenantSnsFields from './custom-fields/DetailTenantSnsFields';
import DetailTenantCustomFields from './custom-fields/DetailTenantCustomField';

const DetailTenantPopupBody = () => {
  // store
  const { currentTenant: tenant } = useTenantStore();
  const langStore = useLanguageStore();

  // ref
  const bodyRef = useRef<HTMLDivElement>(null);

  // hooks
  const customFieldManager = useCustomField();

  /**
   * useEffect, init 스크롤 위치 상단으로 지정
   */
  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0;
    }
  }, []);

  /**
   * @desc custom-fields 조회
   * @returns
   */
  const getCustomField = async () => {
    if (tenant?.content?.id === undefined || tenant?.pointId === undefined) return;
    //  point id
    await customFieldManager.getCustomField(tenant?.content.id, tenant?.pointId);
  };

  useEffect(() => {
    getCustomField();
  }, [langStore.currentLang]);

  return (
    <div ref={bodyRef} className={styles.body_wrapper}>
      {/* 카테고리 */}
      {tenant && tenant?.keywords.length > 0 && (
        <div className={styles.category_wrapper}>
          {tenant?.keywords.map((keyword: string) => (
            <div className={styles.category}>{keyword}</div>
          ))}
        </div>
      )}
      {/* 썸네일 */}
      <div className={styles.image_wrapper}>
        <div className={styles.thumbnail}>
          <img
            src={`${tenant?.subLogoUri || DEFAULT_IMAGE}`}
            alt='thumbnail'
            onError={e => {
              const el = e.target as HTMLImageElement;
              el.src = DEFAULT_IMAGE;
            }}
          />
        </div>

        {/** 위치지정, 즐겨찾기, 공유하기 버튼 영역 */}
        <PopupInnerButtons />
      </div>

      {/* tenant 정보 */}
      {tenant && (
        <div className={styles.text_details_wrapper}>
          {/* 설명 */}
          {tenant?.description[langStore.currentLang] && (
            <div className={styles.desc_wrapper}>
              <pre className={styles.desc}>
                {tenant?.description[langStore.currentLang].replaceAll('\\n', '\n') || '-'}
              </pre>
            </div>
          )}
          <div className={styles.custom_field_wrapper}>
            {/* 커스텀 필드 */}
            <DetailTenantCustomFields tenantCustomFields={tenant.tenantCustomFields} />
            {/* 커스텀 SNS 필드 */}
            <DetailTenantSnsFields />
          </div>
        </div>
      )}
    </div>
  );
};
export default DetailTenantPopupBody;
