import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import { ApproachMetric } from 'types/metric/approachMetric.type';

/**
 * 모바일 기기 진입 통계 수집
 */
const useApproachMetric = () => {
  // hook
  const apiManager = useAxios();

  /**
   * 모바일 기기 진입 통계 수집
   * @param {ApproachMetric} approachRequest
   */
  const postApproach = async (approachRequest: ApproachMetric) => {
    await apiManager.api.post(EndPoint.approach, approachRequest);
  };

  return {
    postApproach,
  };
};
export default useApproachMetric;
