import ROTATION_ON from 'assets/rotation/rotation-on.png';
import ROTATION_FIXED from 'assets/rotation/rotation-fixed.png';
import ROTATION_OFF from 'assets/rotation/rotation-off.png';
import BOOKMARK_SHORTCUT from 'assets/floor/bookmark-shortcut.png';

export const IMAGES = {
  ROTATION_ON,
  ROTATION_FIXED,
  ROTATION_OFF,

  BOOKMARK_SHORTCUT,
};
