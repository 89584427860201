import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import useTenantStore from 'store/tenant';
import useKeywordStore from 'store/keyword';
import { useTranslation } from 'react-i18next';
import SearchSvg from 'components/common/icon/search/SearchSvg';
import CloseSvg from 'components/common/icon/close/CloseSvg';
import { useEffect } from 'react';
import ArrowLeftSvg from 'components/common/icon/arrow/left/ArrowLeftSvg';
import styles from './TenantHeader.module.scss';

const TenantHeader = () => {
  // 다국어
  const { t } = useTranslation();

  // store
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();
  const keywordStore = useKeywordStore();

  // const border = keyword ? `1px solid ${colorSetStore.input.active}` : `1px solid ${colorSetStore.input.inactive}`;
  // const color = keywordStore.keyword.text ? colorSetStore.input.active : colorSetStore.input.inactive;
  const hasTenants = tenantStore.filteredTenants && tenantStore.filteredTenants.length > 0;

  /**
   * @desc keyword input 초기화
   */
  const clearKeyword = () => {
    keywordStore.clearInput();
  };

  /**
   * @desc searchWord의 값 변화에 따라 Tenant 값이 조회 된다.
   * @param searchWord
   * @returns
   */
  const handleTenantsByKeyword = (searchWord: string) => {
    if (!tenantStore.searchTenants) return;
    tenantStore.setFilteredTenants(tenantStore.searchTenants, langStore.currentLang, searchWord);
  };

  /**
   * @desc searchWord의 값 변화에 따라 keyword 값이 조회 된다.
   */
  const handleFilterKeyword = () => {
    keywordStore.setFilterKeyword(keywordStore.content, keywordStore.keyword.text);
  };

  /**
   * @desc input 입력시  keywordStore.setKeyword에 input 저장
   * @param event
   */
  const handleKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    keywordStore.setKeyword({ text: e.target.value, type: 'input' }); // setKeyword(e.target.value);
  };

  /**
   * useEffect
   * keywordStore.keyword.text, langStore.currentLang 가 변경되는 경우 keyword by tenant 검색 처리
   */
  useEffect(() => {
    // keyword 클릭 후 tenant filtering 방지
    if (keywordStore.keyword.type !== 'keyword') {
      handleTenantsByKeyword(keywordStore.keyword.text);
    }
    handleFilterKeyword();
  }, [keywordStore.keyword.text, langStore.currentLang]);

  /**
   * useEffect
   * keyword 초기화
   * keyword by tenant 검색 초기화
   */
  useEffect(() => {
    return () => {
      keywordStore.clearInput(); // setKeyword('');
      handleTenantsByKeyword('');
    };
  }, []);

  const color = keywordStore.keyword.text.length > 0 ? colorSetStore.input.active : colorSetStore.input.inactive;
  // const border =
  //   keywordStore.keyword.text.length > 0
  //     ? `1px solid ${colorSetStore.input.active}`
  //     : `1px solid ${colorSetStore.input.inactive}`;

  return (
    <div
      className={styles.header}
      style={{
        background: colorSetStore.header.bg,
      }}
    >
      {/* type에 [input, keyword]에 따른 디자인 처리 */}
      {keywordStore.keyword.type === 'input' ? (
        <div
          className={styles.field_wrapper}
          // style={{ border }}
        >
          {/* icon */}
          {hasTenants ? (
            <div className={styles.search_icon}>
              <SearchSvg color={color} />
            </div>
          ) : (
            <div className={styles.before_icon} onClick={clearKeyword}>
              <ArrowLeftSvg color={color} />
            </div>
          )}
          {/* input */}
          <div className={styles.input_wrapper}>
            <input
              onChange={handleKeyword}
              value={keywordStore.keyword.text || ''}
              className={styles.search_input}
              style={{
                background: colorSetStore.input.bg,
                color,
              }}
              type='text'
              placeholder={t(`tenant.search.placeholder`)}
            />
            <div className={styles.circle_close_icon} onClick={clearKeyword}>
              <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4.932 3.98682L4.08594 4.8378L8.15358 8.92908L4.0863 13.02L4.93237 13.871L8.99965 9.78007L13.0669 13.871L13.913 13.02L9.84572 8.92908L13.9133 4.83783L13.0673 3.98685L8.99965 8.0781L4.932 3.98682Z'
                  fill='white'
                />
              </svg>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.keyword_wrapper}>
          <div className={styles.keyword}>{keywordStore.keyword.text}</div>
          <div className={styles.close_icon} onClick={clearKeyword}>
            <CloseSvg color='#000' />
          </div>
        </div>
      )}
    </div>
  );
};
export default TenantHeader;
