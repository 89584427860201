/* eslint-disable react/destructuring-assignment */
import CloseButton from 'components/popup/tenant/close/CloseButton';
import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import useShareModalStore from 'store/shareModal';
import styles from './ModalBottomSheetLayout.module.scss';

type Props = {
  isOpenPopup: boolean;
  onClosePopup: (() => Promise<void>) | (() => void);
  children: React.ReactElement;
  dim?: boolean;
  outsideClose?: boolean;
  closeButton?: boolean;
  zIndex?: number;
  disableOutside?: boolean;
};

const ModalBottomSheetLayout = (props: Props) => {
  const shareModalStore = useShareModalStore();

  const onPopupClose = async () => {
    if (props.outsideClose) {
      await props.onClosePopup();
    }
  };
  return (
    <div
      style={{
        zIndex: props.zIndex,
      }}
      className={`${styles.background} ${props.dim ? styles.outside_dim : styles.no_outside_dim}`}
    >
      <OutsideClickHandler
        disabled={shareModalStore.openSharePopup || props.disableOutside}
        display='contents'
        onOutsideClick={onPopupClose}
      >
        <div className={`${styles.wrapper} ${props.isOpenPopup ? styles.open : styles.close}`}>
          {props.closeButton && (
            <div className={styles.close_btn_wrapper}>
              <CloseButton closePopup={onPopupClose} />
            </div>
          )}

          <div className={styles.popup_wrapper}>{props.children}</div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
export default ModalBottomSheetLayout;

ModalBottomSheetLayout.defaultProps = {
  closeButton: true,
  outsideClose: true,
};
