import { ME_DEFAULT } from 'data/image';
import MapDraw from 'map/MapDraw';
import { PositionNum } from 'map/type/position.type';
import { moveCamera } from './camera';
import { clearMap } from './common/clear';
import { validMap } from './common/valid';

// 내 위치 마커 표시(ANI)
export const showMyLocationWithAni = async (iconUrl: string) => {
  validMap();

  // if (MapDraw.originFloorId === getCurrentFloor()?.id) {
  await MapDraw.map.mylocation.set({
    x: MapDraw.mobileMapPosition.coordinate.x,
    y: MapDraw.mobileMapPosition.coordinate.y,
    floorId: MapDraw.originFloorId,
    iconOption: {
      positionZ: 0,
      iconUrl,
      width: 56,
      height: 56,
    },
    animate: {
      color: '#4869df',
      opacity: 0.2,
      desireScale: 3,
      duration: 1500,
      // repeat: 3,
    },
    onActive: true,
    gpsOption: {
      leftCourseDistance: 50,
      willArriveRadius: 100,
      arrivedRadius: 50,
    },
  });
  // }
};

// 내 위치 마커 표시(NO ANI)
export const showMyLocationNoAni = async (iconUrl?: string, position?: PositionNum) => {
  validMap();

  // 내 층일 경우만 gps 켜주기
  // if (MapDraw.originFloorId === getCurrentFloor()?.id) {
  await MapDraw.map.mylocation.set({
    x: position?.x || MapDraw.mobileMapPosition.coordinate.x,
    y: position?.y || MapDraw.mobileMapPosition.coordinate.y,
    floorId: MapDraw.originFloorId,
    iconOption: {
      positionZ: 0,
      iconUrl: `/${iconUrl}` || `/${ME_DEFAULT}`,
      width: 56,
      height: 56,
    },
    onActive: true,
    gpsOption: {
      leftCourseDistance: 50,
      willArriveRadius: 100,
      arrivedRadius: 50,
    },
  });
  // }
};

// 내 위치 마커로 이동
export const goToMyLocation = async () => {
  clearMap();

  await showMyLocationNoAni();
  moveCamera(MapDraw.mobileMapPosition.coordinate);

  // ! indoor tracking 이후 복구
  // ! clear id 로 api cell 요청 필요
  // ! api cell 문의 완료 -> anim timer 추가 해주시기로 함
  // // 5초 후 애니메이션 제거된 현재위치 마커 표시
  // const timer = setTimeout(async () => {
  //   clearMyLocation();

  //   // 애니메이션 나오는 도중 층이 변경되었을 경우 현재위치 아이콘 나오지 않도록 처리
  //   if (MapDraw.machineFloorId === getCurrentFloor().id) {
  //     await showMyLocationNoAni();
  //   }
  //   clearTimeout(timer);
  // }, 5000);
};
