import SearchSvg from 'components/common/icon/search/SearchSvg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useColorSetStore from 'store/colorSet';
import styles from './BookmarkFilter.module.scss';

type BookmarkFilterProps = {
  filterBookmarksByKeyword: (keyword: string) => void;
};

const BookmarkFilter = ({ filterBookmarksByKeyword }: BookmarkFilterProps) => {
  // state
  const [keyword, setKeyword] = useState<string>('');
  // hooks
  const colorSetStore = useColorSetStore();
  const { t } = useTranslation();
  // variable
  const border = keyword ? `1px solid ${colorSetStore.input.active}` : `1px solid ${colorSetStore.input.inactive}`;
  const color = keyword ? colorSetStore.input.active : colorSetStore.input.inactive;

  /**
   * 즐겨찾기 목록 아이템 클릭 이벤트 핸들러
   * @function handleInputKeywordChange
   * @param {React.ChangeEvent<HTMLInputElement>} e input 변경 이벤트 객체
   * @returns {void}
   */
  const handleInputKeywordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setKeyword(e.target.value);
  };

  /**
   * useEffect, input 내 입력 값이 변경되면 목록 조회
   * 현재는 api가 없는 상태로 local storage에 저장된 목록 filter 처리
   */
  useEffect(() => {
    filterBookmarksByKeyword(keyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  return (
    <div
      className={styles.filter}
      style={{
        background: colorSetStore.header.bg,
      }}
    >
      <div
        className={styles.field_wrapper}
        style={{
          border,
        }}
      >
        <SearchSvg color={color} />

        <input
          onChange={handleInputKeywordChange}
          value={keyword || ''}
          className={styles.search_input}
          style={{
            background: colorSetStore.input.bg,
            color,
          }}
          type='text'
          placeholder={t(`bookmark.placeholder`)}
        />
      </div>
    </div>
  );
};

export default BookmarkFilter;
