/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-new */
/* eslint-disable no-console */
import { ME_DEFAULT, ME_TRACKING } from 'data/image';
import { LOCAL_STORAGE_MOTION_PERMISSION_KEY } from 'data/localStorageKey';
import { moveCamera } from 'map/control/camera';
import { clearIndoorTracking } from 'map/control/common/clear';
import { showMyLocationNoAni, showMyLocationWithAni } from 'map/control/myLocation';
import { enableMapRotate, startIndoorTracking } from 'map/control/tracking';
import MapDraw from 'map/MapDraw';
import { useEffect, useState } from 'react';
import useMapActionStore from 'store/action';
import useLoadingStore from 'store/loading';
import useNaviStore from 'store/navi';
import useTenantStore from 'store/tenant';
import useTransportStore from 'store/transport';
import { PermissionType } from 'types/common/permission.type';
import { ITrackingInfo } from 'types/common/trackingInfo.type';
import { returnIndoorTrackingOption } from 'types/map/tracking.type';
import useCheckValidRoute from '../check/useCheckValidRoute';
import useMapRoute from '../event/useMapRoute';
import useMapFloor from '../floor/useMapFloor';

/**
 * 길찾기 route를 그린 후 방향 탐지
 */
const useMapTracking = () => {
  const loadingStore = useLoadingStore();
  const naviStore = useNaviStore();
  const transportStore = useTransportStore();
  const tenantStore = useTenantStore();
  const mapActionStore = useMapActionStore();

  // state
  const [trackingMoveResult, setTrackingMoveResult] = useState<ITrackingInfo[]>([]);

  // hook
  const { changeMapFloor } = useMapFloor();
  const mapRouteManager = useMapRoute();
  const { checkValidRoute } = useCheckValidRoute();

  // 모션 동의 여부
  const localStorageMotionPermission = localStorage.getItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY);
  const isMotionPermissionGranted =
    localStorageMotionPermission && (localStorageMotionPermission as PermissionType) === 'granted';
  const isMotionPermissionDenied =
    localStorageMotionPermission && (localStorageMotionPermission as PermissionType) === 'denied';

  /**
   * ! 단층 길안내일 경우 모의주행 경로를 그린다
   * ! 복층은 TransportPopup 에서 경로 그린다
   */
  const drawRouteByFloor = async () => {
    const multiFloor =
      (tenantStore.currentTenant && tenantStore.currentTenant.content.floorId) !== MapDraw.originFloorId;

    const findDiffFloor = naviStore.naviRoutes.recommendation?.locations.find(
      (location: any) => location.floorId !== tenantStore.currentTenant?.content.floorId,
    );

    if (!multiFloor && !findDiffFloor) {
      await mapRouteManager.drawRoute('recommendation');
    }
  };

  /**
   * ! 지우지마세요
   * gps 모드에서 길찾기 시 이동할 때 마다 이벤트가 발생한다
   * 하나의 이벤트가 한번의 걸음이라고 가정한다
   * @param trackingMoveEvent
   */
  const onTrackingMove = (trackingMoveEvent: CustomEventInit) => {
    const event: ITrackingInfo = trackingMoveEvent.detail;

    setTrackingMoveResult(prev => [...prev, event]);
  };

  /**
   * 모의주행 경로를 그리고
   * indoor tracking 을 활성화한다
   */
  const drawRouteAndTurnOnTracking = async () => {
    if (!MapDraw.map) return;

    await clearIndoorTracking();

    const naviRoute = naviStore.naviRoutes?.[transportStore.transport];
    if (!naviRoute) return;

    /**
     * 지도 층을 변경한다
     */
    await changeMapFloor(MapDraw.originPointId, MapDraw.originFloorId);

    /**
     * 내위치 마커(화살표 이미지) 그리기
     * indoorStart 하기 전에 my location 을 먼저 불러야 한다
     */
    await showMyLocationWithAni(ME_TRACKING);
    moveCamera(MapDraw.mobileMapPosition.coordinate);

    // 손가락으로 지도 회전 불가
    enableMapRotate(false);

    /**
     * indoor tracking service 를 시작한다
     * 시작할 때는 기기 위치값으로 넣어준다
     */
    await startIndoorTracking(
      returnIndoorTrackingOption(
        // ! 지도 회전 제거
        MapDraw.mobileMapPosition.coordinate,
        // Boolean(isMotionPermissionGranted),
        // Boolean(isMotionPermissionGranted),
        false,
        false,
      ),
    );

    // 단층 길안내일 경우 모의주행 경로를 그린다
    await drawRouteByFloor();

    // await trackingOn(naviRoute);
  };

  /**
   * 모의주행 경로를 그린다
   */
  const drawRouteAndTurnOffTracking = async () => {
    if (!MapDraw.map) return;
    if (!tenantStore.currentTenant) return;

    await changeMapFloor(MapDraw.originPointId, MapDraw.originFloorId);
    enableMapRotate(false);

    const enableRoute = await checkValidRoute(tenantStore.currentTenant);

    if (enableRoute) {
      // 단층 길안내일 경우 모의주행 경로를 그린다
      await drawRouteByFloor();
    }

    await showMyLocationNoAni(ME_DEFAULT);
    moveCamera(MapDraw.mobileMapPosition.coordinate);
  };

  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      if (mapActionStore.actions.indoorTracking) {
        if (isMotionPermissionGranted) {
          /**
           * tracking-move 이벤트 핸들러를 등록한다
           */
          // MapDraw.mapContainer.addEventListener('tracking-move', onTrackingMove);

          drawRouteAndTurnOnTracking();
        } else if (isMotionPermissionDenied) {
          drawRouteAndTurnOffTracking();
        }
      }
    }

    // TODO: cleanup
    return () => {
      /**
       * tracking-move 이벤트 핸들러를 제거한다
       */
      setTrackingMoveResult([]);
      // MapDraw.mapContainer.removeEventListener('tracking-move', onTrackingMove);
    };
  }, [loadingStore.isLoadingMap, mapActionStore.actions.indoorTracking]);

  const trackingResult = trackingMoveResult[trackingMoveResult.length - 1];
  const lastLocation =
    trackingResult &&
    trackingResult.current_path &&
    trackingResult.current_path[trackingResult.current_path.length || 1 - 1];

  const firstLocation = trackingResult && trackingResult.current_path && trackingResult.current_path[0];

  const trackings: { title: string; value: string }[] = [
    {
      title: '현상태',
      value: trackingResult ? trackingResult.current_state : '-',
    },
    {
      title: '내 위치 상태',
      value: trackingResult ? trackingResult.current_state : '-',
    },
    {
      title: '현위치',
      value: `x: ${String(trackingResult ? trackingResult.current_position.x : '-')}, y: ${String(
        trackingResult ? trackingResult.current_position.y : '-',
      )}`,
    },
    {
      title: '이전 지점과의 거리',
      value: String(trackingResult ? trackingResult.distance_from_previous : '-'),
    },
    {
      title: '가장 가까운 지점',
      value: `x: ${String(trackingResult ? trackingResult.closestPoint.x : '-')}, y: ${String(
        trackingResult ? trackingResult.closestPoint.y : '-',
      )}`,
    },
    {
      title: '출발지 거리',
      value: String(trackingResult ? trackingResult.distance_from_origin : '-'),
    },
    {
      title: '현재 path',
      value: `시작점 x: ${String(trackingResult ? trackingResult.current_path?.[0].position.x : '-')}, y: ${String(
        trackingResult ? trackingResult.current_path?.[0].position.y : '-',
      )} ~ 끝점 x: ${String(lastLocation ? lastLocation.position.x : '-')}, y: ${String(
        lastLocation ? lastLocation.position.y : '-',
      )} `,
    },
    {
      title: '현재 path 각도',
      value: String(trackingResult ? trackingResult.current_angle : '-'),
    },
    {
      title: '다음 방향',
      value: String(firstLocation ? firstLocation.direction : '-'),
    },
    {
      title: 'poiId',
      value: String(firstLocation ? firstLocation.poiId : '-'),
    },
    {
      title: '방향',
      value: String(firstLocation ? firstLocation.direction : '-'),
    },
  ];

  return { trackingMoveResult, trackings };
};
export default useMapTracking;
