export const PAGE_SIZE = 10;

export const MARKER_ICON_GREEN = '/assets/icon/arrive-green.png';

// 일반 파란색 마커 옵션
export const DEFAULT_MARKER_OPTION = {
  positionZ: 50,
  // anchor: {
  //   x: 0.5,
  //   y: 0.5,
  // },
  iconUrl: '/assets/icon/arrive.png',
  width: 14,
  height: 20,
  visibleIcon: true,
};

// 주차용 초록색 마커 옵션
export const PARKING_MARKER_OPTION = {
  positionZ: 50,
  iconUrl: '/assets/icon/arrive-green.png',
  width: 37,
  height: 52,
  visibleIcon: true,
};
