import React from 'react';
import useKeywordStore from 'store/keyword';
import useTenantStore from 'store/tenant';
import styles from './KeywordContainer.module.scss';
import Keyword from '../keyword/Keyword';

const KeywordContainer = () => {
  const keywordStore = useKeywordStore();
  const tenantStore = useTenantStore();
  const hasTenants = tenantStore.filteredTenants && tenantStore.filteredTenants.length > 0;

  return (
    <div className={`${styles.keyword_container} ${!hasTenants && styles.noTenant}`}>
      {keywordStore.filterContent.map(
        (keyword: string, idx: number) =>
          idx < 6 && <Keyword key={idx} obj={keywordStore.content?.[keyword] || null} />,
      )}
    </div>
  );
};

export default KeywordContainer;
