import { findObject } from 'map/control/object';
import { PositionNum } from 'map/type/position.type';

// objectId 로 object 를 찾아서 object position return
export const returnObjectPosition = (objectId: string): PositionNum => {
  const object = findObject({ id: objectId });
  if (object) {
    return object.position;
  }
  return { x: 0, y: 0 };
};
