import { LOCAL_STORAGE_PARKING_LOCATION_KEY } from 'data/localStorageKey';
import useMachine from 'hooks/api/machine/useMachine';
import useUsageMetric from 'hooks/api/metric/useUsageMetric';
import useMapRoute from 'hooks/map/event/useMapRoute';
import useMapFloor from 'hooks/map/floor/useMapFloor';
import { moveCamera } from 'map/control/camera';
import { drawMarker } from 'map/control/marker';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useClientStore from 'store/client';
import useDomainStore from 'store/domain';
import useLanguageStore from 'store/languages';
import useLoadingStore from 'store/loading';
import useModalStore from 'store/modal';
import useTenantStore from 'store/tenant';
import { PathQueryParam, TypeQueryParam } from 'types/common/queryParam.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { extractParams } from 'util/params/join/extract/extractParams';
import { sortByLangOrder } from 'util/sort/lang-order/sortByLangOrder';
import { findTenant } from 'util/tenant/findTenant';

/**
 * URL의 type query string별 action을 위한 hook (층별안내 페이지에서 실행)
 */
const useTypeQueryString = () => {
  // hook
  const location = useLocation();
  const mapRouteManager = useMapRoute();
  const { changeMapStates, changeMapFloor } = useMapFloor();
  const statsManager = useUsageMetric();

  // store
  const tenantStore = useTenantStore();
  const modalStore = useModalStore();
  const loadingStore = useLoadingStore();
  const langStore = useLanguageStore();
  const domainStore = useDomainStore();
  const clientStore = useClientStore();

  // variables
  const [pointIdParam, setPointIdParam] = useState<string | undefined>(undefined);
  const [machineIdParam, setMachineIdParam] = useState<string | undefined>(undefined);
  const [typeParam, setTypeParam] = useState<string | undefined>(undefined);
  const [destTenantIdParam, setDestTenantIdParam] = useState<string | undefined>(undefined);
  const [originTenantIdParam, setOriginTenantIdParam] = useState<string | undefined>(undefined);

  // variables
  // const pointIdParam: string | undefined = extractParams(location.search, 'pointId');
  // const machineIdParam: string | undefined = extractParams(location.search, 'machineId');
  // action type
  // const typeParam: TypeQueryParam | undefined = extractParams(location.search, 'type');
  // 도착지
  // const destTenantIdParam: string | undefined = extractParams(location.search, 'destTenantId');
  // 출발지
  // const originTenantIdParam: string | undefined = extractParams(location.search, 'originTenantId');

  // WEB 접속이 추가 됨에 따라 분기 로직
  const init = async () => {
    if (domainStore.domainType === 'WEB') {
      if (clientStore.client) {
        setPointIdParam(clientStore.client.id);
        setMachineIdParam('');
      }
    }
    if (domainStore.domainType === 'MOBILE') {
      setPointIdParam(extractParams(location.search, 'pointId'));
      setMachineIdParam(extractParams(location.search, 'machineId'));
    }
    setTypeParam(extractParams(location.search, 'type'));
    setDestTenantIdParam(extractParams(location.search, 'destTenantId'));
    setOriginTenantIdParam(extractParams(location.search, 'originTenantId'));
  };

  useEffect(() => {
    init();
  }, []);

  // TODO: 기존 URL 정리 후 제거
  const pathParam: PathQueryParam | undefined = extractParams(location.search, 'path');
  const tenantIdParam: string | undefined = extractParams(location.search, 'tenantId');

  // 주차위치
  const storageParkingLocation = localStorage.getItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
  const myParkingLocation: TenantWithPointId = storageParkingLocation ? JSON.parse(storageParkingLocation || '') : '';

  /**
   * 테넌트 상세 팝업을 연다.
   */
  const openTenantDetailModal = () => {
    if (!destTenantIdParam) return;
    const currentTenant = findTenant(tenantStore.rawTenants, destTenantIdParam);

    if (!currentTenant) return;
    // 테넌트가 다중 poi 인지 확인한다.
    const findTenants = tenantStore.poiTenantsMap?.get(currentTenant.content.poiId);

    if (!findTenants) return;

    if (findTenants.length > 1) {
      const sortedTenants: TenantWithPointId[] = findTenants.sort((a, b) =>
        sortByLangOrder(a.name[langStore.currentLang], b.name[langStore.currentLang]),
      );

      tenantStore.setCurrentTenant(sortedTenants[0]);
      tenantStore.setCurrentTenantArr(sortedTenants);
      modalStore.setOpenTenantListModal(true);
    } else {
      tenantStore.setCurrentTenant(findTenants[0]);
      modalStore.setOpenTenantModal(true);
    }
  };

  /**
   * URL type 에 따라 다른 action 실행
   */
  const triggerActionByType = async () => {
    /**
     * URL type 이 location 인 경우 해당 tenant 위치를 확인한다 (마커 + 카메라 이동)
     * 현재 위치는 변경되지 않는다
     */
    if (typeParam === 'location') {
      if (!pointIdParam || !destTenantIdParam) return;

      // URL type location 일 경우 매장 사용량을 수집한다.
      await statsManager.postTenantUsage(pointIdParam, destTenantIdParam, 'LOCATION', machineIdParam);

      // 마커 생성 및 카메라 이동
      const result = await mapRouteManager.showTenantLocation(destTenantIdParam, tenantStore.rawTenants);
      await drawMarker('default', result?.floorId || '', result?.position || { x: 0, y: 0 });
      moveCamera(result?.position || { x: 0, y: 0 });

      // 테넌트 갯수에 따른 테넌트 상세 모달을 연다.
      openTenantDetailModal();

      return;
    }

    /**
     * URL type 이 parkingDetail 일 경우 내 주차위치 확인 팝업(MyParkingLocationPopup)을 노출한다
     */
    if (typeParam === 'parkingDetail') {
      if (!destTenantIdParam) return;

      const parkingLocation = findTenant(tenantStore.rawTenants, destTenantIdParam);
      if (!parkingLocation) return;

      changeMapStates(parkingLocation.pointId, parkingLocation.content.floorId);

      modalStore.setOpenMyParkingModal(true);

      localStorage.setItem(LOCAL_STORAGE_PARKING_LOCATION_KEY, JSON.stringify(parkingLocation));
      const result = await mapRouteManager.showTenantLocation(parkingLocation.id, tenantStore.rawTenants);
      await drawMarker('parking', result?.floorId || '', result?.position || { x: 0, y: 0 });
      moveCamera(result?.position || { x: 0, y: 0 });
      return;
    }

    /**
     * URL type 이 parkingPositioning 일 경우
     * 기존 등록되어 있던 localStorage 비워주고,
     * 내 주차위치 지정해주는 팝업(SaveMyParkingLocationPopup)을 보여준다.
     *
     * 기존에 주차위치가 등록되어 있었다면 해당 위치에 주차 마커만 생성한다.
     */
    if (typeParam === 'parkingPositioning') {
      if (!originTenantIdParam) return;
      /**
       * 주차 위치가 있든 없든 무조건 URL의 tenant를 현재위치로 변경한다
       */
      const originTenant = findTenant(tenantStore.rawTenants, originTenantIdParam);

      if (!originTenant) return;
      changeMapStates(originTenant.pointId, originTenant.content.floorId);
      await mapRouteManager.changeOriginLocation(originTenantIdParam);

      /**
       * 기존에 주차위치가 등록되어 있었는지 확인한다
       */
      if (!myParkingLocation || (myParkingLocation && originTenantIdParam !== myParkingLocation.id)) {
        localStorage.removeItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);

        modalStore.setOpenParkingModal(true);
      }

      const result = await mapRouteManager.showTenantLocation(originTenantIdParam, tenantStore.rawTenants);
      await drawMarker('parking', result?.floorId || '', result?.position || { x: 0, y: 0 });
      moveCamera(result?.position || { x: 0, y: 0 });
      return;
    }

    /**
     * QR type 이 parkingNavi 인 경우 내 주차위치까지 길찾기 팝업(NaviToMyParkingLocationPopup)을 연다
     */
    if (typeParam === 'parkingNavi') {
      if (!originTenantIdParam) return;

      /**
       * 저장된 주차 위치가 있을 경우 현재위치를 출발지로 지정하고, 저장된 주차위치를 도착지로 지정한다 (팝업에서)
       */
      if (myParkingLocation) {
        changeMapStates(myParkingLocation.pointId, myParkingLocation.content.floorId);

        modalStore.setOpenParkingNaviModal(true);
      } else {
        /**
         * 저장된 주차 위치가 없을 경우 URL의 tenant를 현재위치로 변경한다
         */
        const originTenant = findTenant(tenantStore.rawTenants, originTenantIdParam);

        if (!originTenant) return;
        changeMapStates(originTenant.pointId, originTenant.content.floorId);

        // 지도 빌딩, 층 변경
        await changeMapFloor(originTenant.pointId, originTenant.content.floorId);
        // 해당 tenant로 현재위치 설정
        await mapRouteManager.changeOriginLocation(originTenantIdParam);
      }
    }
  };

  // URL 에 tenantId 있을 경우
  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      triggerActionByType();
    }
  }, [loadingStore.isLoadingMap, typeParam, destTenantIdParam, originTenantIdParam, tenantStore.rawTenants]);

  // TODO: 기존 URL 정리 후 제거
  const handleQrPath = async () => {
    if (!tenantIdParam) return;

    /**
     * QR type 이 tenant 인 경우 해당 tenant 위치를 확인한다 (마커 + 카메라 이동)
     * 현재 위치는 변경되지 않는다
     */
    if (pathParam === 'tenant') {
      if (!pointIdParam) return;

      // 매장 사용량 수집
      await statsManager.postTenantUsage(pointIdParam, tenantIdParam, 'LOCATION', machineIdParam);

      const result = await mapRouteManager.showTenantLocation(tenantIdParam, tenantStore.rawTenants);
      await drawMarker('default', result?.floorId || '', result?.position || { x: 0, y: 0 });
      moveCamera(result?.position || { x: 0, y: 0 });

      // 테넌트 갯수에 따른 테넌트 상세 모달을 연다.
      openTenantDetailModal();
      return;
    }

    /**
     * QR type 이 myParking 일 경우(type=parkingDetail) 내 주차위치 확인 팝업(MyParkingLocationPopup을 노출한다
     */
    if (pathParam === 'myParking') {
      const parkingLocation = findTenant(tenantStore.rawTenants, tenantIdParam);

      if (!parkingLocation) return;
      changeMapStates(parkingLocation.pointId, parkingLocation.content.floorId);

      modalStore.setOpenMyParkingModal(true);

      localStorage.setItem(LOCAL_STORAGE_PARKING_LOCATION_KEY, JSON.stringify(parkingLocation));
      const result = await mapRouteManager.showTenantLocation(parkingLocation.id, tenantStore.rawTenants);
      await drawMarker('parking', result?.floorId || '', result?.position || { x: 0, y: 0 });
      moveCamera(result?.position || { x: 0, y: 0 });
      return;
    }

    /**
     * QR type 이 parking 일 경우(type=parkingPositioning)
     * 기존 등록되어 있던 localStorage 비워주고,
     * 내 주차위치 지정해주는 팝업(SaveMyParkingLocationPopup을 보여준다.
     *
     * 기존에 주차위치가 등록되어 있었다면 해당 위치에 주차 마커만 생성한다.
     */
    if (pathParam === 'parking') {
      /**
       * 주차 위치가 있든 없든 무조건 URL의 tenant를 현재위치로 변경한다
       */
      const originTenant = findTenant(tenantStore.rawTenants, tenantIdParam);

      if (!originTenant) return;
      changeMapStates(originTenant.pointId, originTenant.content.floorId);
      await mapRouteManager.changeOriginLocation(tenantIdParam);

      /**
       * 기존에 주차위치가 등록되어 있었는지 확인한다
       */
      if (!myParkingLocation || (myParkingLocation && tenantIdParam !== myParkingLocation.id)) {
        localStorage.removeItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);

        modalStore.setOpenParkingModal(true);
      }

      const result = await mapRouteManager.showTenantLocation(tenantIdParam, tenantStore.rawTenants);
      await drawMarker('parking', result?.floorId || '', result?.position || { x: 0, y: 0 });
      moveCamera(result?.position || { x: 0, y: 0 });
      // return;
    }

    /**
     * QR type 이 parkingNavi 인 경우 내 주차위치까지 길찾기 팝업(NaviToMyParkingLocationPopup을 연다
     */
    if (pathParam === 'parkingNavi') {
      /**
       * 저장된 주차 위치가 있을 경우 현재위치를 출발지로 지정하고, 저장된 주차위치를 도착지로 지정한다 (팝업에서)
       */
      if (myParkingLocation) {
        changeMapStates(myParkingLocation.pointId, myParkingLocation.content.floorId);

        modalStore.setOpenParkingNaviModal(true);
      } else {
        /**
         * 저장된 주차 위치가 없을 경우 URL의 tenant를 현재위치로 변경한다
         */
        const originTenant = findTenant(tenantStore.rawTenants, tenantIdParam);

        if (!originTenant) return;
        changeMapStates(originTenant.pointId, originTenant.content.floorId);

        // 지도 빌딩, 층 변경
        await changeMapFloor(originTenant.pointId, originTenant.content.floorId);
        // 해당 tenant로 현재위치 설정
        await mapRouteManager.changeOriginLocation(tenantIdParam);
      }
    }
  };

  // TODO: 기존 URL 정리 후 제거
  // param 에 tenantId 있을 경우
  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      handleQrPath();
    }
  }, [loadingStore.isLoadingMap, pathParam, tenantIdParam, tenantStore.rawTenants]);

  return { triggerActionByType, handleQrPath };
};
export default useTypeQueryString;
