/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-new */
/* eslint-disable no-console */
import { ME_DEFAULT, ME_TRACKING } from 'data/image';
import { clearIndoorTracking } from 'map/control/common/clear';
import { showMyLocationNoAni } from 'map/control/myLocation';
import { enableMapRotate, startIndoorTracking } from 'map/control/tracking';
import MapDraw from 'map/MapDraw';
import useTrackingStore, { TrackingType } from 'store/tracking';
import { returnIndoorTrackingOption } from 'types/map/tracking.type';

/**
 * 방향 탐지
 */
const useMapDirection = () => {
  const trackingStore = useTrackingStore();

  /**
   * 방향 설정 기능을 켠다
   */
  const onDirectionTracking = async () => {
    // ! 지도 회전 제거
    // trackingStore.setTrackingType('ON');

    await clearIndoorTracking();

    /**
     * 화살표를 그린다
     * ! indoorStart 하기 전에 my location 을 먼저 불러야 한다
     */
    await showMyLocationNoAni(ME_TRACKING);

    /**
     * 지도 회전을 유지한채로 indoor tracking service 를 시작한다
     * 시작할 때는 기기 위치값으로 넣어준다
     */
    await startIndoorTracking(
      returnIndoorTrackingOption(
        //
        MapDraw.mobileMapPosition.coordinate,
        true,
        false,
      ),
    );
  };

  /**
   * 방향을 고정한다
   */
  const fixCurrentRotation = async () => {
    trackingStore.setTrackingType('FIXED');

    await clearIndoorTracking();

    // ! 지도 회전 제거
    // changeCamera({ rotation: trackingStore.mapRotation, transition: true });

    /**
     * isHeading -> 지도 회전
     * keepRotation -> 지도 회전이 false 일 때 각도 유지
     *
     * ! 지도 회전 제거, 내위치 각도 유지
     */
    MapDraw.map.mylocation.indoorSetHeading({ isHeading: false, keepRotation: false });

    /**
     * 화살표를 그린다
     * ! indoorStart 하기 전에 my location 을 먼저 불러야 한다
     */
    await showMyLocationNoAni(ME_TRACKING);

    /**
     * 지도는 고정한채로 indoor tracking service 를 시작한다
     */
    await startIndoorTracking(
      returnIndoorTrackingOption(
        //
        MapDraw.mobileMapPosition.coordinate,
        false,
        false,
      ),
    );
  };

  /**
   * 방향 설정을 끈다
   */
  const offDirectionTracking = async () => {
    trackingStore.setTrackingType('OFF');

    enableMapRotate(false);

    // ! 지도 회전 제거
    // trackingStore.setMapRotation(MapDraw.mobileMapPosition.rotation);
    // changeCamera({ rotation: MapDraw.mobileMapPosition.rotation, transition: true });

    // indoor tracking 서비스를 종료한다
    await clearIndoorTracking();

    // 내위치 아이콘을 표시한다
    await showMyLocationNoAni(ME_DEFAULT);
  };

  /**
   * trackingType 에 따라 action을 변경한다
   * @param {TrackingType} trackingType
   */
  const switchActionByTrackingType = async (trackingType: TrackingType) => {
    switch (trackingType) {
      // ! 지도 회전 제거
      // case 'ON':
      //   await onDirectionTracking();

      //   break;

      case 'FIXED':
        await fixCurrentRotation();

        break;

      case 'OFF':
        await offDirectionTracking();

        break;

      default:
        // ! 지도 회전 제거
        // await onDirectionTracking();
        await fixCurrentRotation();

        break;
    }
  };

  return { onDirectionTracking, fixCurrentRotation, offDirectionTracking, switchActionByTrackingType };
};
export default useMapDirection;

// if (MapDraw.machineFloorId !== getCurrentFloor().id) {
//   await changeMapFloor(MapDraw.pointId, MapDraw.machineFloorId);
// }
