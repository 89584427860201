import InitialLoadingPage from 'components/common/loader/page/InitialLoadingPage';
import AlertPopup from 'components/popup/alert/AlertPopup';
import SharePopup from 'components/popup/share/SharePopup';
import CommonToastPopup from 'components/popup/toast/CommonToastPopup';
import { LOCAL_STORAGE_MOTION_PERMISSION_KEY } from 'data/localStorageKey';
import useFetchApis from 'hooks/api/fetch/useFetchApis';
import useMobileUsage from 'hooks/api/metric/useMobileUsage';
import useNavigateByUrlQueryString from 'hooks/url/useNavigateByUrlQueryString';
import useVerifyUrl from 'hooks/url/useVerifyUrl';
import AllViewPage from 'pages/AllViewPage';
import BookmarkPage from 'pages/BookmarkPage';
import CustomBoardPage from 'pages/CustomBoardPage';
import ErrorPage from 'pages/ErrorPage';
import FloorPage from 'pages/FloorPage';
import MainPage from 'pages/MainPage';
import TenantPage from 'pages/TenantPage';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import useCommonStore from 'store/common';
import useLoadingStore from 'store/loading';
import usePopupStore from 'store/popup';
import useShareModalStore from 'store/shareModal';
import useTimerStore from 'store/timer';
import { PathType } from 'types/common/path.type';
import { PermissionType } from 'types/common/permission.type';
import { handleViewHeight } from 'util/vh/handleViewHeight';
import { v4 as uuidv4 } from 'uuid';
import Layout from './components/layout/Layout';

// Kakao 타입 전역으로 설정
declare global {
  interface Window {
    Kakao: any;
  }
}

function App() {
  // store
  const loadingStore = useLoadingStore();
  const popupStore = usePopupStore();
  const timerStore = useTimerStore();
  const shareModalStore = useShareModalStore();
  const commonStore = useCommonStore();

  // hook
  useVerifyUrl(); // 전시 전용 URL로 pointId, tenantId 조회
  useNavigateByUrlQueryString(); // URL 타입에 따라 층별안내로 이동
  useFetchApis(); // resource API 조회
  useMobileUsage(); // 기기 사용량 수집

  // 모션 동의 여부
  const localStorageMotionPermission = localStorage.getItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY);
  const isMotionPermissionGranted =
    localStorageMotionPermission && (localStorageMotionPermission as PermissionType) === 'granted';

  // ! pointId 없이 들어왔을 경우 alert 및 다비오 홈페이지로 이동
  const redirectPage = () => {
    window.location.href = 'https://www.dabeeo.com/';
    popupStore.setOpenErrorPopup(false);
  };

  // 카카오 SDK 초기화
  const initKakaoSdk = () => {
    window.Kakao.init(process.env.REACT_APP_KAKAO_JS_KEY);
  };

  /**
   * 고유한 uuid를 생성한다.
   */
  useEffect(() => {
    const generateUuid = () => {
      const uuid = uuidv4();
      commonStore.setAppUuid(uuid.replace(/-/g, '').toUpperCase());
    };

    generateUuid();
  }, []);

  useEffect(() => {
    initKakaoSdk();
  }, []);

  useEffect(() => {
    handleViewHeight();
  }, []);

  return (
    <>
      {loadingStore.isInitialLoading ? (
        <InitialLoadingPage />
      ) : (
        <Routes>
          <Route element={<Layout />}>
            {/* 기본 경로 */}
            <Route path={PathType.root} element={<MainPage />} />
            <Route path={PathType.main} element={<MainPage />} />

            {/* 개별 페이지 */}
            <Route path={PathType.floor} element={<FloorPage />} />
            <Route path={PathType.tenant} element={<TenantPage />} />
            <Route path={PathType.error} element={<ErrorPage />} />
            <Route path={PathType.customBoard} element={<CustomBoardPage />} />
            <Route path={PathType.bookmark} element={<BookmarkPage />} />
            <Route path={PathType.more} element={<AllViewPage />} />

            {/* /web/:id 하위 경로 */}
            <Route path={`${PathType.web}/:clientPath`}>
              <Route index element={<MainPage />} /> {/* /web/:id */}
              <Route path={PathType.floor} element={<FloorPage />} /> {/* /web/:id/floor */}
              <Route path={PathType.tenant} element={<TenantPage />} /> {/* /web/:id/tenant */}
              <Route path={PathType.error} element={<ErrorPage />} /> {/* /web/:id/error */}
              <Route path={PathType.customBoard} element={<CustomBoardPage />} /> {/* /web/:id/customBoard */}
              <Route path={PathType.bookmark} element={<BookmarkPage />} /> {/* /web/:id/bookmark */}
              <Route path={PathType.more} element={<AllViewPage />} /> {/* /web/:id/more */}
            </Route>
          </Route>
        </Routes>
      )}
      {popupStore.openErrorPopup && <AlertPopup closePopup={redirectPage} message='올바르지 않은 접근입니다.' />}

      {/* toast 팝업 */}
      {popupStore.openToastPopup && isMotionPermissionGranted && (
        <CommonToastPopup
          closePopupAfterSeconds={() => {
            timerStore.closeToastPopupAfterSeconds(() => {
              popupStore.setOpenToastPopup(false);
              timerStore.clearToastPopupTimer();
            });
          }}
          text={popupStore.toastMessage}
          isOpen={popupStore.openToastPopup}
        />
      )}

      {/* 공유하기 팝업 */}
      {shareModalStore.openSharePopup && (
        <SharePopup
          onClosePopup={() => {
            shareModalStore.setOpenSharePopup(false);
          }}
        />
      )}
    </>
  );
}

export default App;
