import { PositionNum } from 'map/type/position.type';

export type IndoorTrackingOption = {
  position: {
    x: number;
    y: number;
  };
  // ! 임의의 값 설정
  height: number; // 키(신장)
  focus: boolean; // 내위치 마커가 항상 지도 중심에 올 지 여부 설정
  heading: boolean; // 지도 회전 여부
  loggingOption: {
    // ! tracking 결과 text 파일 다운로드 막으려면 옵션 둘 다 끄기
    enableLocal: boolean;
    enableNetwork: boolean;
  };
  angleOnly: boolean; // indoor tracking 방향만 반영
};

export const returnIndoorTrackingOption = (
  originPosition: PositionNum,
  heading: boolean,
  focus: boolean,
): IndoorTrackingOption => {
  return {
    position: {
      x: originPosition.x,
      y: originPosition.y,
    },
    height: 160,
    focus,
    heading,
    loggingOption: {
      enableLocal: false,
      enableNetwork: false,
    },
    angleOnly: true,
  };
};
