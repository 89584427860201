import { useEffect } from 'react';
import useMapActionStore from 'store/action';
import useLoadingStore from 'store/loading';
import useNaviStore from 'store/navi';
import useTransportStore from 'store/transport';

// 이동수단 선택
const useMapTransport = () => {
  // store
  const transportStore = useTransportStore();
  const naviStore = useNaviStore();
  const loadingStore = useLoadingStore();
  const mapActionStore = useMapActionStore();

  const triggerTransport = async () => {
    transportStore.setLoadingTransport(true);

    transportStore.setTransports(Object.keys(naviStore.naviRoutes));
    transportStore.setOpenTransportPopup(true);

    transportStore.setLoadingTransport(false);
  };

  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      if (mapActionStore.actions.transport) {
        triggerTransport();
      }
    }
  }, [mapActionStore.actions.transport, loadingStore.isLoadingMap]);

  return {
    triggerTransport,
  };
};
export default useMapTransport;
