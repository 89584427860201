import { isEmpty } from 'lodash';
import MapDraw from 'map/MapDraw';
import { findRoute } from 'map/control/route';
import { useState } from 'react';
import useNaviStore from 'store/navi';
import useShowStore from 'store/show';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { warnConsole } from 'util/common/console';

// 팝업 진입시, 길안내가 가능한지 여부를 체크하는 함수를 관리하는 custom hook
const useCheckValidRoute = () => {
  const [routeLoading, setRouteLoading] = useState(true);
  const [notAvailableNavi, setNotAvailableNavi] = useState(false);

  const naviRouteStore = useNaviStore();
  const showStore = useShowStore();

  /**
   * @param tenant 길찾기 진행할 테넌트 정보
   * @returns 길찾기 가능한지 여부 반환
   */
  const checkValidRoute = async (tenant: TenantWithPointId): Promise<boolean> => {
    const routeResponse = await findRoute(MapDraw.mobileMapPosition.coordinate, tenant);

    if (isEmpty(routeResponse)) {
      warnConsole('길찾기 경로가 없습니다. : routeResponse 가 {} 입니다.');

      setRouteLoading(false);
      setNotAvailableNavi(true);
      showStore.setShowNavi(false);
      return false;
    }

    // 전역 store 에 저장
    naviRouteStore.setNaviRoutes(routeResponse);

    const totalDistances = [
      {
        route: 'recommendation',
        totalDistance: routeResponse.recommendation?.totalDistance || 0,
      },
      {
        route: 'elevator',
        totalDistance: routeResponse.elevator?.totalDistance || 0,
      },
      {
        route: 'escalator',
        totalDistance: routeResponse.escalator?.totalDistance || 0,
      },
      {
        route: 'stairs',
        totalDistance: routeResponse.stairs?.totalDistance || 0,
      },
    ];

    const noDistance = totalDistances.every(distance => !distance.totalDistance);

    if (noDistance) {
      warnConsole('길찾기 경로가 없습니다. : 경로의 totalDistance 가 모두 0 입니다.');

      setRouteLoading(false);
      setNotAvailableNavi(true);
      showStore.setShowNavi(false);
      return false;
    }

    setRouteLoading(false);
    setNotAvailableNavi(false);
    showStore.setShowNavi(true);
    return true;
  };

  return {
    checkValidRoute,
    routeLoading,
    notAvailableNavi,
  };
};

export default useCheckValidRoute;
