import { LOCAL_STORAGE_BOOKMARK_KEY } from 'data/localStorageKey';
import { convert } from 'hangul-romanization';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import useBookmarkStore from 'store/bookmark';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import BookmarkList from './body/BookmarkList';
import BookmarkFilter from './filter/BookmarkFilter';
import BookmarkHeader from './header/BookmarkHeader';

type BookmarksType = {
  origin: TenantWithPointId[] | undefined;
  filteredData: TenantWithPointId[] | undefined;
};

type Props = {
  closeBookmarks: () => void;
};

const BookmarkContainer = ({ closeBookmarks }: Props) => {
  // state
  const [bookmarks, setBookmarks] = useState<BookmarksType>({
    origin: undefined,
    filteredData: undefined,
  });
  // store
  const bookmarkStore = useBookmarkStore();

  /**
   * 즐겨찾기 목록 정렬 후 반환
   * @function sortedBookmarks
   * @param {TenantWithPointId[]} sortBookmarks 정렬 대상 즐겨찾기 목록 데이터
   * @returns {TenantWithPointId[]}
   */
  const sortedBookmarks = (sortBookmarks: TenantWithPointId[]): TenantWithPointId[] => {
    return sortBookmarks.sort((a, b) =>
      a.searchKeyword < b.searchKeyword ? -1 : a.searchKeyword > b.searchKeyword ? 1 : 0,
    );
  };

  /**
   * 즐겨찾기 검색 처리, TODO: api 가 없는 상태로 완전한 like 검색 구현 안됨
   * @function filterBookmarksByKeyword
   * @param {string} keyword 검색 키워드
   * @returns {void}
   */
  const filterBookmarksByKeyword = (keyword: string): void => {
    if (bookmarks.origin && keyword) {
      const normalizedKeyword = convert(keyword).toLowerCase();
      const result = bookmarks.origin?.filter((bookmark: TenantWithPointId) => {
        return convert(bookmark.searchKeyword).toLowerCase().includes(normalizedKeyword);
      });
      setBookmarks({ ...bookmarks, filteredData: result });
    }
    if (bookmarks.origin && !keyword) {
      setBookmarks({ ...bookmarks, filteredData: bookmarks.origin });
    }
  };

  /**
   * useEffect, bookmark 목록 set state
   */
  useEffect(() => {
    const bookmarkList = localStorage.getItem(LOCAL_STORAGE_BOOKMARK_KEY);
    setBookmarks({
      origin: bookmarkList ? sortedBookmarks(JSON.parse(bookmarkList)) : [],
      filteredData: bookmarkList ? sortedBookmarks(JSON.parse(bookmarkList)) : [],
    });
  }, []);

  useEffect(() => {
    if (bookmarkStore.bookmarkRefresh) {
      const bookmarkList = localStorage.getItem(LOCAL_STORAGE_BOOKMARK_KEY);
      setBookmarks({
        origin: bookmarkList ? sortedBookmarks(JSON.parse(bookmarkList)) : [],
        filteredData: bookmarkList ? sortedBookmarks(JSON.parse(bookmarkList)) : [],
      });
      bookmarkStore.setBookmarkRefresh(false);
    }
  }, [bookmarkStore, bookmarkStore.bookmarkRefresh]);

  return (
    <div>
      <BookmarkHeader onClickGoBack={closeBookmarks} title={t('bookmark.bookmark')} />
      <BookmarkFilter filterBookmarksByKeyword={filterBookmarksByKeyword} />
      {bookmarks.filteredData && <BookmarkList closeBookmarks={closeBookmarks} bookmarks={bookmarks.filteredData} />}
    </div>
  );
};
export default BookmarkContainer;
