import ArrowLeftSvg from 'components/common/icon/arrow/left/ArrowLeftSvg';
import useColorSetStore from 'store/colorSet';
import styles from './BookmarkHeader.module.scss';

type BookmarkHeaderProps = {
  title: string;
  onClickGoBack: () => void;
};

const BookmarkHeader = ({ title, onClickGoBack }: BookmarkHeaderProps) => {
  // store
  const colorSetStore = useColorSetStore();

  return (
    <div
      className={styles.header}
      style={{
        background: colorSetStore.header.bg,
        color: colorSetStore.header.text,
      }}
    >
      <div className={styles.arrow} onClick={onClickGoBack}>
        <ArrowLeftSvg color={colorSetStore.header.text} />
      </div>
      {title}
    </div>
  );
};

export default BookmarkHeader;
