/* eslint-disable no-nested-ternary */
import DabeeoLogoSvg from 'components/common/icon/logo/DabeeoLogoSvg';
import MenuIcon from 'components/layout/menu-bar/menus/menu-icon/MenuIcon';
import { LOCAL_STORAGE_PARKING_LOCATION_KEY } from 'data/localStorageKey';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import useMachineStore from 'store/machine';
import useMenuStore from 'store/menu';
import usePointStore from 'store/point';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { extractParams } from 'util/params/join/extract/extractParams';
import { joinParams } from 'util/params/join/joinParams';
import useDomainStore from 'store/domain';
import styles from './AllViewContainer.module.scss';
import BusinessHours from './business-hours/BusinessHours';
import ClosedDays from './closed-days/ClosedDays';
import MyBookmark from './more-info/MyBookmark';
import MyParkingLocation from './more-info/MyParkingLocation';
import MultiLangBtn from './multi-lang/MultiLangBtn';

// 전체보기 페이지
const AllViewContainer = () => {
  // hook
  const location = useLocation();
  const { t } = useTranslation();
  const { clientPath } = useParams(); // 동적 파라미터 읽기

  // store
  const pointStore = usePointStore();
  const menuStore = useMenuStore();
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();
  const machineStore = useMachineStore();
  const domainStore = useDomainStore();

  // variable
  const storageParkingLocation = localStorage.getItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
  const currentParkingLot: TenantWithPointId = storageParkingLocation ? JSON.parse(storageParkingLocation || '') : '';
  const pointIdParam: string | undefined = extractParams(location.search, 'pointId');

  // state
  const [includePoint, setIncludePoint] = useState(false);

  const isShowBusinessHours = pointStore.point?.hoursUsed;
  const isShowClosedDays = pointStore.point?.closedDaysUsed;

  useEffect(() => {
    if (pointStore.pointsMap) {
      if (pointIdParam) {
        const findPoint = pointStore.pointsMap.get(pointIdParam);
        if (findPoint) {
          setIncludePoint(true);
        }
      }
    }
  }, [pointIdParam, pointStore.pointsMap]);

  // 내 주차위치 노출 여부
  const isShowParkingLocation = currentParkingLot && includePoint;

  // 다국어 노출 여부
  const isShowMultiLangs = langStore.languages.length >= 2;

  // domain type 에 따른 분기
  const setTo = (type: string) => {
    if (domainStore.domainType === 'WEB') {
      return `/web/${clientPath}/${type}`;
    }
    return `/${type}?${joinParams(location.search)}`;
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.list_wrapper}
        style={{
          background: colorSetStore.header.bg,
        }}
      >
        <div className={styles.menu_list}>
          {/* 전체보기 */}
          <div
            className={styles.header}
            style={{
              color: colorSetStore.header.text,
            }}
          >
            {t(`menu.more`)}
          </div>

          {/* 메뉴 */}
          <section className={styles.menus}>
            {menuStore.mainMenus
              .filter(menu => !(menu.type === 'home' || menu.type === 'mobile'))
              .map(({ type, name, iconUsed, mainIconUri, customPageId }, index) => (
                <Link
                  key={index}
                  className={styles.menu}
                  style={{
                    color: colorSetStore.header.text,
                  }}
                  to={setTo(type)}
                  state={{ pageId: customPageId }}
                >
                  {iconUsed && (
                    <div className={styles.menu_icon}>
                      <MenuIcon iconUri={mainIconUri} iconColor={colorSetStore.header.text} />
                    </div>
                  )}

                  <span>{name[langStore.currentLang]}</span>
                </Link>
              ))}
          </section>

          <div className={styles.more_info_wrapper}>
            {/* 즐겨찾기 */}
            <MyBookmark />

            {/* 내 주차위치 */}
            {machineStore.machine?.workspace.parkingUsed && isShowParkingLocation && <MyParkingLocation />}
          </div>
        </div>

        {/* 다국어, 로고 */}
        <div className={styles.bottom_wrapper}>
          {/* 다국어 */}
          {isShowMultiLangs && <MultiLangBtn />}

          {/* 로고 */}
          <div
            className={styles.dabeeo}
            style={{
              color: colorSetStore.footer.text,
            }}
          >
            Powered by
            <DabeeoLogoSvg color={colorSetStore.header.text} />
          </div>
        </div>
      </div>

      {/* 운영시간, 휴점일 */}
      <div className={styles.footer}>
        {isShowBusinessHours && <BusinessHours />}
        {isShowClosedDays && <ClosedDays />}
      </div>
    </div>
  );
};
export default AllViewContainer;
