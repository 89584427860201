import React from 'react';
import useKeywordStore from 'store/keyword';
import { Content } from 'types/tenant/keyword.type';
import useLanguageStore from 'store/languages';
import useTenantStore from 'store/tenant';
import styles from './Keyword.module.scss';

type Props = {
  obj: Content | null;
};

const Keyword = ({ obj }: Props) => {
  // store
  const keywordStore = useKeywordStore();
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();

  /**
   * @desc keywordStore의 text와 일치하는 문자를 highlight 한다.
   * @param keyword
   * @returns
   */
  const highlightText = (keyword: string) => {
    const query = keywordStore.keyword.text;
    if (query === '') return keyword;
    const parts = keyword.split(new RegExp(`(${query})`, 'gi'));

    return (
      <>
        {parts.map((part: string, index: number) =>
          part.toLowerCase() === query.toLowerCase()
            ? part.split('')?.map((p: string, idx: number) => (
                <span key={index + idx} style={{ color: '#4F74F9' }}>
                  {p}
                </span>
              ))
            : part
                .split('')
                ?.map((p: string, idx: number) =>
                  p === ' ' ? <span key={index + idx}>&nbsp;</span> : <span key={index + idx}>{p}</span>,
                ),
        )}
      </>
    );
  };

  const handleClickKeyword = () => {
    if (!obj) return;
    keywordStore.setKeyword({ text: obj.title, type: 'keyword' });

    tenantStore.setFilteredTenants(
      tenantStore.searchTenants,
      langStore.currentLang,
      keywordStore.keyword.text,
      obj?.title,
    );
  };

  return (
    <div className={styles.keyword} onClick={handleClickKeyword}>
      {obj && highlightText(obj.title)}
    </div>
  );
};

export default Keyword;
