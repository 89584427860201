/* eslint-disable react/jsx-no-useless-fragment */
import { t } from 'i18next';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import usePointStore from 'store/point';
import styles from './BusinessHours.module.scss';

const BusinessHours = () => {
  const pointStore = usePointStore();
  const colorSetStore = useColorSetStore();
  const langStore = useLanguageStore();

  return (
    <>
      {pointStore.point?.hoursUsed ? (
        // 운영시간 사용하는 경우
        <div
          className={`${styles.time}`}
          style={{
            background: colorSetStore.footer.bg,
          }}
        >
          <div
            className={styles.time_title}
            style={{
              color: colorSetStore.footer.text,
            }}
          >
            {t(`info.businessHours`)}
          </div>
          <div
            className={styles.time_content}
            style={{
              color: colorSetStore.footer.text,
            }}
          >
            {pointStore.point?.hoursType === 'DATE' ? (
              // DATE 타입
              <>
                {pointStore.point.hoursStart}-{pointStore.point.hoursEnd}
              </>
            ) : (
              // TEXT 타입
              <>{pointStore.point?.hoursText[langStore.currentLang]}</>
            )}
          </div>
        </div>
      ) : (
        // 운영시간 사용하지 않는 경우
        <></>
      )}
    </>
  );
};
export default BusinessHours;
