import { create } from 'zustand';

// ! 지도 회전 제거
// export type TrackingType = 'ON' | 'FIXED' | 'OFF';
export type TrackingType = 'FIXED' | 'OFF';

type TrackingStore = {
  trackingType: TrackingType;
  setTrackingType: (trackingType: TrackingType) => void;

  isOpenPermissionAlert: boolean;
  setOpenPermissionAlert: (isOpenPermissionAlert: boolean) => void;

  // ! 지도 회전 제거
  // mapRotation: number;
  // setMapRotation: (mapRotation: number) => void;
};

const useTrackingStore = create<TrackingStore>((set, get) => ({
  // ! 지도 회전 제거
  trackingType: 'FIXED',
  setTrackingType(trackingType: TrackingType) {
    set(() => ({
      trackingType,
    }));
  },

  isOpenPermissionAlert: false,
  setOpenPermissionAlert(isOpenPermissionAlert: boolean) {
    set(() => ({
      isOpenPermissionAlert,
    }));
  },

  // ! 지도 회전 제거
  // mapRotation: 0,
  // setMapRotation(mapRotation: number) {
  //   set(() => ({
  //     mapRotation,
  //   }));
  // },
}));

export default useTrackingStore;
