/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
import DisabledScreen from 'components/common/screen/disable/DisabledScreen';
import useUsageMetric from 'hooks/api/metric/useUsageMetric';
import { clearIndoorTracking } from 'map/control/common/clear';
import { enableMapRotate } from 'map/control/tracking';
import MapDraw from 'map/MapDraw';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useMapActionStore from 'store/action';
import useColorSetStore from 'store/colorSet';
import useCommonStore from 'store/common';
import useLoadingStore from 'store/loading';
import useMachineStore from 'store/machine';
import useNaviStore from 'store/navi';
import useTenantStore from 'store/tenant';
import useTransportStore from 'store/transport';
import { PathType } from 'types/common/path.type';
import { errorConsole } from 'util/common/console';
import useDomainStore from 'store/domain';
import styles from '../MapActionButton.module.scss';

type Props = {
  buttonText: string;
  closePopup?: () => void;
  parkingNavi?: boolean;
};

const MapNavigationButton = (props: Props) => {
  // store
  const transportStore = useTransportStore();
  const mapActionStore = useMapActionStore();
  const tenantStore = useTenantStore();
  const themeStore = useColorSetStore();
  const naviStore = useNaviStore();
  const machineStore = useMachineStore();
  const loadingStore = useLoadingStore();
  const commonStore = useCommonStore();
  const domainStore = useDomainStore();
  // hook
  const navigate = useNavigate();
  const { search: queryParams, pathname } = useLocation();
  const { postTenantUsage } = useUsageMetric();
  const { clientPath } = useParams();

  /**
   * 모의보행을 시작한다
   */
  const onClickStartNavigate = async () => {
    if (!tenantStore.currentTenant || !machineStore.machine) {
      return;
    }

    // 매장 사용량을 수집한다 (통계)
    try {
      await postTenantUsage(
        machineStore.machine.point.id,
        tenantStore.currentTenant?.id || '',
        'NAVIGATION',
        machineStore.machine.id,
      );
    } catch (error) {
      errorConsole('매장 사용량 통계 에러입니다.', error);
    }

    try {
      loadingStore.setActionLoading(true);

      if (!commonStore.hasMachineId) {
        naviStore.setNaviType('INIT');
      } else {
        naviStore.setNaviType('ON_ACTIVE');

        // indoor tracking 서비스를 종료한다
        await clearIndoorTracking();

        // 길찾기 시에는 지도 회전을 막는다
        enableMapRotate(false);
      }

      if (props.closePopup) {
        props.closePopup();
      }
      moveToFloorPage();

      // 주차 길찾기인 경우, machineId 없는 경우
      if (props.parkingNavi || !commonStore.hasMachineId) {
        checkToTransportPopup();
        return;
      }

      mapActionStore.setMapAction('navigation', true);
      loadingStore.setActionLoading(false);
    } catch (error) {
      loadingStore.setActionLoading(false);
      errorConsole('모의보행을 실패했습니다.', error);
    }
  };

  /**
   * 이동수단 선택이 필요한지 확인한다.
   */
  function checkToTransportPopup() {
    /**
     * 이동수단을 선택해야 하는 경우
     *
     * 다른 건물 혹은 다른 층으로 길찾기 하는 경우 (복층 길찾기)
     * 층이 끊어져있다면 (가산퍼블릭몰처럼 추천경로에서 층을 비교했을 때 층이 달라진다면 끊어져있다고 간주한다)
     * 이동수단을 선택한다.
     */
    const multiFloor =
      (tenantStore.currentTenant && tenantStore.currentTenant.content.floorId) !== MapDraw.originFloorId;

    const findDiffFloor = naviStore.naviRoutes.recommendation?.locations.find(
      (location: any) => location.floorId !== tenantStore.currentTenant?.content.floorId,
    );
    if (multiFloor || findDiffFloor) {
      mapActionStore.setMapAction('transport', true);
      loadingStore.setActionLoading(false);

      return;
    }

    mapActionStore.setMapAction('navigation', true);
    loadingStore.setActionLoading(false);
  }

  /**
   * 층별안내로 이동한다
   */
  function moveToFloorPage() {
    // 모의주행 시작
    loadingStore.setNavi(true);

    if (pathname !== `/${PathType.floor}`) {
      // domain type 에 따른 분기
      if (domainStore.domainType === 'WEB') {
        navigate(`/web/${clientPath}/${PathType.floor}`);
      } else {
        navigate(`/${PathType.floor}${queryParams}`);
      }
    }
  }

  useEffect(() => {
    return () => {
      naviStore.setNaviType('INIT');
    };
  }, []);

  return (
    <>
      <button
        style={{
          background: `${
            naviStore.naviType === 'INIT' ? themeStore.main : naviStore.naviType === 'ON_ACTIVE' ? '#F2F5FF' : '#272A36'
          }`,
        }}
        type='button'
        disabled={transportStore.isLoadingTransport || naviStore.naviType === 'ON_ACTIVE'}
        onClick={onClickStartNavigate}
        className={styles.wrapper}
      >
        {/* 시작 아이콘 */}
        {naviStore.naviType !== 'ON_ACTIVE' && (
          <div className={styles.icon}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
              <path d='M9 18L9 6L17.6603 12L11.1051 16.5415' stroke='white' strokeWidth='1.2' />
            </svg>
          </div>
        )}

        {/* 길찾기 시작 / 모의보행 중 / 다시보기 */}
        <div
          className={styles.button_text}
          style={{
            color: `${naviStore.naviType === 'ON_ACTIVE' ? '#7B8CC8' : '#fff'}`,
          }}
        >
          {naviStore.naviType === 'INIT'
            ? props.buttonText
            : naviStore.naviType === 'ON_ACTIVE'
            ? '모의보행 중...'
            : '다시보기'}
        </div>
      </button>

      {transportStore.isLoadingTransport && <DisabledScreen />}
    </>
  );
};
export default MapNavigationButton;
