/* eslint-disable no-console */
import { LOCAL_STORAGE_BOOKMARK_KEY, LOCAL_STORAGE_PARKING_LOCATION_KEY } from 'data/localStorageKey';
import useCategoryGroup from 'hooks/api/category-group/useCategoryGroup';
import useLanguage from 'hooks/api/language/useLanguage';
import useMainMenu from 'hooks/api/menu/main/useMainMenu';
import useScreensaver from 'hooks/api/screensaver/useScreensaver';
import MapDraw from 'map/MapDraw';
import { getFloorDataBeforeGetObject } from 'map/control/floor';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useApproachStore from 'store/approach';
import useLanguageStore from 'store/languages';
import useLoadingStore from 'store/loading';
import useMapStore from 'store/map';
import { LangCode, Language } from 'types/language/language.type';
import { Machine } from 'types/machine/machine.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { errorConsole, logConsole } from 'util/common/console';
import { getTimeStamp } from 'util/date-time/timeStamp';
import { getBrowserLanguage } from 'util/multi-lang/getBrowserLanguage';
import { extractParams, extractPath, extractUrlDomainType } from 'util/params/join/extract/extractParams';
import useDomainStore from 'store/domain';
import useKeyword from '../keyword/useKeyword';
import useMachine from '../machine/useMachine';
import usePoint from '../point/usePoint';
import useApproachMetric from '../metric/useApproachMetric';
import useClient from '../client/useClient';

const useFetchApis = () => {
  // hook
  const { getMachine, handleMachineData } = useMachine();
  const { getLanguages } = useLanguage();
  const { getScreensavers } = useScreensaver();
  const { getCategoryGroupsWithTenant } = useCategoryGroup();
  const { getMainMenu } = useMainMenu();
  const { getPoints } = usePoint();
  const { getPoiContentKeyword } = useKeyword();
  const location = useLocation();
  const approachMetricManager = useApproachMetric();
  const clientManager = useClient();
  const TIME_STAMP_FORMAT = 'mm:ss:SSS';

  // store
  const loadingStore = useLoadingStore();
  const languageStore = useLanguageStore();
  const mapStore = useMapStore();
  const approachStore = useApproachStore();

  // state
  const [pointIdParam, setPointIdParam] = useState<string | undefined>('');

  // WEB 도메인 처리
  const handleWebDomain = async () => {
    // pathname 에서 clientPath 추출
    const clientPath: string = extractPath(location.pathname);

    try {
      if (!clientPath) return;
      // clientPath 추출
      const result = await clientManager.getClient({ clientPath });
      if (!result) throw new Error('클라이언트 정보를 가져오지 못했습니다.');

      setPointIdParam(result.id);
    } catch (error) {
      errorConsole('클라이언트 path를 불러올 수 없습니다.', error);
    }
  };

  // Mobile Point ID 도메인 처리
  const handleMobileDomain = () => {
    const pointId = extractParams(location.search, 'pointId');
    setPointIdParam(pointId);
  };

  const domainStore = useDomainStore();

  // 초기에 domain type에 따라 조회 로직 분기
  const init = async () => {
    const domainType = extractUrlDomainType(location);

    domainStore.setDomainType(domainType);
    if (domainType === 'WEB') {
      await handleWebDomain();
    }
    if (domainType === 'MOBILE') {
      handleMobileDomain();
    }
  };

  const storageParkingLocation = localStorage.getItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
  const storageBookmarks = localStorage.getItem(LOCAL_STORAGE_BOOKMARK_KEY);

  /**
   * 저장된 localStorage 의 workspace와 machine의 workspace 가 다를 경우 저장된 localStorage를 모두 clear 한다
   => clear 되는 값은 현재위치, 주차위치, 즐겨찾기 값임 (2024/06/28 기준)
   
   * TODO: 전시 솔루션 들어가면 워크스페이스가 달라도 주차위치는 유지해야 할 수도 있기 때문에 전시솔루션+주차위치 들어가면 이 부분 수정해야 함
   * @param machineWorkspaceId 기기의 workspace 아이디
   */
  const clearStorageWhenDiffWorkspace = async (machineWorkspaceId: string, comparePointId: string) => {
    const machine = await getMachine(comparePointId);
    if (machine) {
      const storageWorkspaceId = machine.workspace.id;

      if (machineWorkspaceId !== storageWorkspaceId) {
        // 모든 storage 값 초기화
        localStorage.clear();
      }
    }
  };

  // 브라우저 언어를 현재 언어로 설정
  const setBrowserLangToCurrentLang = (mainLang: LangCode, langs: Language[]) => {
    const browserLang = getBrowserLanguage();

    // 브라우저 언어가 5가지 언어가 아닐 경우 메인 언어 반환
    if (!browserLang) {
      languageStore.setCurrentLang(mainLang);
      return mainLang;
    }

    // 브라우저 언어가 노출 언어와 하나도 맞지 않으면 메인 언어 반환
    const hasBrowserLangInLangs: boolean = langs.some(lang => lang.code === browserLang);

    if (!hasBrowserLangInLangs) {
      languageStore.setCurrentLang(mainLang);
      return mainLang;
    }

    // default 는 브라우저 언어
    languageStore.setCurrentLang(browserLang);
    return browserLang;
  };

  /**
   * @param mainLang - 다국어 변환 시 기준이 되는 메인 언어 (어드민에서 설정)
   * @param initLang - 처음에 기본으로 보여줄 언어 (브라우저에서 설정)
   * @param workspaceId
   */
  const fetchAllOnce = async (mainLang: LangCode, initLang: LangCode, workspaceId: string, pointId: string) => {
    const result = await Promise.all([
      getPoints(mainLang, workspaceId, pointId),
      getScreensavers(pointId),
      getCategoryGroupsWithTenant(mainLang, initLang, workspaceId, pointId),
      getMainMenu(mainLang, pointId),
      getPoiContentKeyword(pointId),
    ]);

    if (result) {
      logConsole(`> promise all fetch end: ${getTimeStamp(TIME_STAMP_FORMAT)}`);
    }
  };

  /**
   * 모든 apis 조회한다.
   */
  const fetchAllApis = async () => {
    if (!pointIdParam) {
      return;
    }

    // 1. machine 조회
    const machine: Machine | undefined = await getMachine(pointIdParam);

    if (machine) {
      // 주차기능 사용하지 않는다면 localStorage 비워준다
      if (!machine.workspace.parkingUsed) {
        localStorage.removeItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
      }

      // 내 주차위치
      const myParkingLocation: TenantWithPointId = storageParkingLocation
        ? JSON.parse(storageParkingLocation || '')
        : '';
      // 즐겨찾기 목록
      const bookmarks: TenantWithPointId[] = storageBookmarks ? JSON.parse(storageBookmarks || '[]') : [];

      if (myParkingLocation || bookmarks.length > 0) {
        // storage 에 저장된 workspace 와 machine 의 workspace 가 다를 경우 저장된 localStorage 값 모두 비워준다.
        await clearStorageWhenDiffWorkspace(machine.workspace.id, myParkingLocation.pointId || bookmarks[0].pointId);
      }

      // 2. language 조회
      const { mainLanguage, langs } = await getLanguages(machine.workspace.id);

      // 처음 보여줄 언어 설정
      const initLang: LangCode = setBrowserLangToCurrentLang(mainLanguage, langs);

      // 3. 서버 api 조회
      await fetchAllOnce(mainLanguage, initLang, machine.workspace.id, machine.point.id);

      // 4. machine 데이터 다국어 변환, 기기 관련 정보 설정, 현재위치 설정
      handleMachineData(machine, mainLanguage);

      logConsole(`> internal server api fetch 완료: ${getTimeStamp(TIME_STAMP_FORMAT)}`);

      try {
        // 4. dabeeoMaps fetch
        const mapData = await MapDraw.getInstance().getMapData({
          mapId: machine.map.id,
          serverType: 'SERVER_STUDIO4',
          clientId: machine.map.temp1,
          clientSecret: machine.map.temp2,
          version: machine.map.version,
        });

        if (mapData) {
          MapDraw.mapData = mapData;

          /**
           * ! 제거 불가능
           * -> 한번에 모든 층정보 가져오는 방법으로 속도 개선해야 함.
           * await mapData.getAllFloorData(floorIds?:string[])
           * https://api-doc.dabeeomaps.com/classes/DabeeoMapData.DabeeoMapData.html#getAllFloorData
           */
          await getFloorDataBeforeGetObject(mapData);

          loadingStore.setHasMapData(true);
        }

        /**
         * 전시명(clientPath)이 있을 때만 진입 통계를 수집한다.
         */
        if (machine.workspace.clientPath) {
          await approachMetricManager.postApproach(approachStore.approachRequest);
        }
      } catch (error) {
        errorConsole(`dabeeoMaps getMapData error: ${error}`);
      } finally {
        // initial loading 관리
        loadingStore.setInitialLoading(false);
        logConsole(`> 초기 로딩 종료: ${getTimeStamp(TIME_STAMP_FORMAT)}`);
      }
    }
  };

  // map sdk를 불러오는 스크립트가 load된 이후에 api 통신 시작
  useEffect(() => {
    if (mapStore.loadMapScript) {
      if (pointIdParam) {
        fetchAllApis();
      }
    }
  }, [mapStore.loadMapScript, pointIdParam]);

  useEffect(() => {
    init();
  }, [location]);

  useEffect(() => {
    logConsole(`> internal server api fetch 시작: ${getTimeStamp(TIME_STAMP_FORMAT)}`);
  }, []);

  return {};
};
export default useFetchApis;
