/* eslint-disable react/destructuring-assignment */
import { useEffect } from 'react';
import usePopupStore from 'store/popup';
import styles from './CommonToastPopup.module.scss';

type Props = {
  isOpen: boolean;
  text: string;
  closePopupAfterSeconds: () => void;
};

const CommonToastPopup = (props: Props) => {
  // store
  const popupStore = usePopupStore();

  useEffect(() => {
    props.closePopupAfterSeconds();

    return () => {
      popupStore.setToastPopupMessage('');
    };
  }, []);

  return (
    <div className={`${styles.container} ${props.isOpen ? styles.open : styles.close}`}>
      {/* <div className={styles.text}>{props.text}</div> */}
      <div className={styles.text}>
        {props.text.split('\n').map((line, index) => (
          <div key={index}>
            {line}
            {index !== props.text.split('\n').length - 1 && <br />}
          </div>
        ))}
      </div>
    </div>
  );
};
export default CommonToastPopup;
