import usePoint from 'hooks/api/point/usePoint';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import styles from './PointName.module.scss';
import DotSpan from '../dot/DotSpan';

type Props = {
  tenant: TenantWithPointId;
};

const PointName = ({ tenant }: Props) => {
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  const { findPointName } = usePoint();

  return (
    <div className={styles.point} style={{ color: colorSetStore.text.category }}>
      {tenant.boothNumber.length > 0 && <DotSpan />}
      {findPointName(tenant.pointId)?.[langStore.currentLang]}
    </div>
  );
};
export default PointName;
