import usePointStore from 'store/point';
import useTenantStore from 'store/tenant';
import FloorName from '../../floor/FloorName';
import PointName from '../../point/PointName';
import styles from './InfoGroup.module.scss';
import BoothName from '../../booth/BoothName';

// floor, point, category name
// TODO: refactor
const InfoGroup = () => {
  const tenantStore = useTenantStore();
  const pointStore = usePointStore();

  if (!tenantStore.currentTenant) {
    return <> </>;
  }

  return (
    <div className={styles.sub_titles}>
      <FloorName tenant={tenantStore.currentTenant} />
      {/* booth or point 하나라도 표시하면 Bar 노출  */}
      {(tenantStore?.currentTenant.boothNumber.length > 0 || pointStore.points.length > 1) && (
        <div className={styles.bar} />
      )}
      {/* BoothName  */}
      {tenantStore?.currentTenant.boothNumber.length > 0 && <BoothName tenant={tenantStore.currentTenant} />}

      {/* PointName  */}
      {pointStore.points.length > 1 && <PointName tenant={tenantStore.currentTenant} />}

      {/* <Divider /> */}
      {/* <div className={styles.divider_dot}>
        <DotSvg color='#999999' />
      </div> */}

      {/* <CategoryName tenant={tenantStore.currentTenant} /> */}
    </div>
  );
};
export default InfoGroup;
