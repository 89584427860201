import { clearAllMarkers, clearNavigation } from 'map/control/common/clear';
import { PoiData } from 'map/type/dataObject.type';
import useMapActionStore from 'store/action';
import useFacilityStore from 'store/facility';
import useModalStore from 'store/modal';
import useTenantStore from 'store/tenant';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { errorConsole } from 'util/common/console';

// multi pois 처리하는 hook
const useMultiPoi = () => {
  const tenantStore = useTenantStore();
  const mapActionStore = useMapActionStore();
  const facilityStore = useFacilityStore();
  const modalStore = useModalStore();

  /**
   * ! multi pois는 일반적인 상황이 아니지만 예외 상황을 위해 처리
   * 이전에 poi를 하나 숨겨놓아서 여러개 poi라고 인식하는 경우가 있어서 처리함 (WIS)
   *
   * @param {PoiData[]} pois poi 목록
   */
  const handleMultiPoi = async (pois: PoiData[]) => {
    /**
     * ! 다중 poi 일 때 0번째 poi 로 무조건 보여주도록 처리 (2024 1202)
     */
    const poiDataId = pois[0].id;
    const findTenant: TenantWithPointId | undefined = tenantStore.rawTenants.find(
      item => item.content.poiId === poiDataId,
    );

    if (!findTenant) {
      errorConsole('해당 poi를 가진 tenant를 찾을 수 없습니다.');
      return;
    }

    if (findTenant) {
      clearAllMarkers();
      clearNavigation();
      mapActionStore.resetMapActions();
      facilityStore.resetCurrentFacilityId();
      tenantStore.resetCurrentTenant();

      // const excludeFirstPoiArr: PoiData[] = pois.slice(1);

      // // 첫번째 테넌트 제외한 배열
      // const excludeFirstTenantArr: TenantWithPointId[] = tenantStore.rawTenants
      //   .filter(rawTenant => excludeFirstPoiArr.some((poi: PoiData) => poi.id === rawTenant.content.poiId))
      //   .sort((a, b) => sortByLangOrder(a.name[langStore.currentLang], b.name[langStore.currentLang]));

      // 테넌트 설정
      tenantStore.setCurrentTenant(findTenant);
      // tenantStore.setCurrentTenantArr([findTenant, ...excludeFirstTenantArr]);
      // modalStore.setOpenTenantListModal(true);

      modalStore.setOpenTenantModal(true);

      // TODO: 다중 poi 일 경우 camera focus 할 position 변경해야 함
      // detail 의 position 하면 안됌
      // object 의 position 으로 할 경우 object find 해야 하고
      // poi 로 할 경우 poi id 로 poi position 찾아야 함
      // moveCamera(objectPosition);
    }
  };

  return { handleMultiPoi };
};
export default useMultiPoi;
