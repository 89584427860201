import { ApproachMetric, ApproachType } from 'types/metric/approachMetric.type';
import { create } from 'zustand';

type Store = {
  approachRequest: ApproachMetric;
  setApproachRequest: (approachRequest: ApproachMetric) => void;
};

const useApproachStore = create<Store>((set, get) => ({
  approachRequest: {
    uuid: '',
    pointId: '',
    tenantId: '',
    buildingId: '',
    poiContentId: '',
    type: ApproachType.BOOTH, // booth type 일 경우 부스번호 필수
    boothNumber: '',
    timestamp: 0,
  },
  setApproachRequest(approachRequest: ApproachMetric) {
    set({
      approachRequest,
    });
  },
}));

export default useApproachStore;
