import { create } from 'zustand';

type CommonStore = {
  // 앱 uuid (새로고침 시 리셋)
  appUuid: string;
  setAppUuid: (appUuid: string) => void;

  // 모션 접근 허용 여부를 보냈는지 여부 (중복 처리 때문)
  sendGranted: boolean;
  setSendGranted: (sendGranted: boolean) => void;

  // 최초 진입 시 URL에 machineId 를 가지고 있는지 여부
  hasMachineId: boolean;
  setHasMachineId: (hasMachineId: boolean) => void;
};
const useCommonStore = create<CommonStore>(set => ({
  appUuid: '',
  setAppUuid(appUuid: string) {
    set(() => ({ appUuid }));
  },

  sendGranted: false,
  setSendGranted(sendGranted: boolean) {
    set(() => ({ sendGranted }));
  },

  hasMachineId: false,
  setHasMachineId(hasMachineId: boolean) {
    set(() => ({ hasMachineId }));
  },
}));

export default useCommonStore;
