import React from 'react';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import useColorSetStore from 'store/colorSet';
import styles from './BoothName.module.scss';

type Props = {
  tenant: TenantWithPointId;
};

const BoothName = ({ tenant }: Props) => {
  const colorSetStore = useColorSetStore();

  return (
    <div className={styles.booth} style={{ color: colorSetStore.text.category }}>
      {tenant?.boothNumber}
    </div>
  );
};

export default BoothName;
