import { Axios } from 'axios';
import { LOCAL_STORAGE_MOTION_PERMISSION_KEY } from 'data/localStorageKey';
import dayjs from 'dayjs';
import useBooth from 'hooks/api/booth/useBooth';
import useMachine from 'hooks/api/machine/useMachine';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useApproachStore from 'store/approach';
import useClientStore from 'store/client';
import useCommonStore from 'store/common';
import useLoadingStore from 'store/loading';
import usePopupStore from 'store/popup';
import useTrackingStore from 'store/tracking';
import { PathType } from 'types/common/path.type';
import { ApproachType } from 'types/metric/approachMetric.type';
import { errorConsole } from 'util/common/console';
import { extractParams } from 'util/params/join/extract/extractParams';

/**
 * 전시 전용 URL 관리하는 hook
 * ex) https://m.did.dabeeo.com/exh/전시명/부스번호
 *     https://localhost:8001/exh/kintex1/12345
 */
const useVerifyUrl = () => {
  // hook
  const location = useLocation();
  const navigate = useNavigate();
  const boothManager = useBooth();
  const machineManager = useMachine();

  // store
  const popupStore = usePopupStore();
  const loadingStore = useLoadingStore();
  const approachStore = useApproachStore();
  const commonStore = useCommonStore();
  const trackingStore = useTrackingStore();

  // var
  const pointIdParam: string | undefined = extractParams(location.search, 'pointId');
  const machineIdParam: string | undefined = extractParams(location.search, 'machineId');

  /**
   * 앱 에러 발생시키고, 로딩을 끈다.
   */
  const causeError = () => {
    loadingStore.setInitialLoading(false);
    loadingStore.setLoadingMap(false);
    loadingStore.setActionLoading(false);
    loadingStore.setFloorChanging(false);

    popupStore.setOpenErrorPopup(true);
  };

  /**
   * 전시 전용 URL 유효성 검사하는 함수
   */
  const verifyExhUrl = async () => {
    try {
      const splitExh = location.pathname.split('/exh')[1];

      // 내용이 없으면 에러가 발생한다.
      if (!splitExh) {
        causeError();
        return;
      }

      const exhibitionTitle = splitExh.split('/')[1];
      const boothNumber = splitExh.split('/')[2];

      // 전시명, 부스번호 중 하나라도 없을 경우 에러가 발생한다.
      if (!exhibitionTitle || !boothNumber) {
        causeError();
        return;
      }

      // 전시명과 부스번호를 통해 pointId, tenantId를 찾는 api를 호출한다.
      const booth = await boothManager.getBooth(exhibitionTitle, boothNumber);

      // 통계 api post 를 위한 데이터를 저장한다.
      approachStore.setApproachRequest({
        uuid: commonStore.appUuid,
        pointId: booth.pointId,
        tenantId: booth.tenantIds[0],
        type: ApproachType.BOOTH,
        boothNumber,
        timestamp: dayjs().valueOf(),
      });

      // 생성한 새로운 URL로 redirect 한다.
      navigate(`/${PathType.floor}?type=location&pointId=${booth.pointId}&destTenantId=${booth.tenantIds[0]}`);
    } catch {
      // 호출 실패하면 에러 발생한다.
      causeError();
    }
  };

  /**
   * machineId만 있는 경우 api 호출해서 point를 찾는다.
   */
  const redirectWithPointId = async () => {
    if (!machineIdParam) {
      return;
    }

    popupStore.setOpenErrorPopup(false);

    try {
      const findPointId = await machineManager.getPointIdByMachineId(machineIdParam);

      if (findPointId) {
        // 페이지 이동
        navigate(`/?pointId=${findPointId}&machineId=${machineIdParam}`);
      }
    } catch (error) {
      if (error instanceof Axios) {
        errorConsole('mvp getMachine error!');
      }
    }
  };

  /**
   * 일반적인 URL의 유효성 검사하는 함수
   */
  const verifyCommonUrl = async () => {
    /**
     * ! machineId가 전시 스케줄러용일 경우에만 pointId를 찾아서 redirect 한다.
     */
    if (machineIdParam === '832455B08557450A9707BB11AA331431') {
      await redirectWithPointId();
    }

    // TODO:
    // pointId 가 없을 경우 에러 발생한다.
    // if (!pointIdParam) {
    //   causeError();
    // }
  };
  /**
   * 처음 진입 시 방향감지상태값은 그냥 무조건 상태값 초기화한다.
   * machineId가 없을 때만 deny로 설정 및 tracking off 한다.
   */
  useEffect(() => {
    if (machineIdParam) {
      commonStore.setHasMachineId(true);
      localStorage.removeItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY);
    } else {
      commonStore.setHasMachineId(false);
      trackingStore.setTrackingType('OFF');
      localStorage.setItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY, 'denied');
    }
  }, [machineIdParam]);

  useEffect(() => {
    // /exh 로 시작한다면 전시 URL임
    if (location.pathname.startsWith('/exh')) {
      if (commonStore.appUuid) {
        verifyExhUrl();
      }
    } else {
      verifyCommonUrl();
    }
  }, [commonStore.appUuid, pointIdParam, machineIdParam]);
};
export default useVerifyUrl;
