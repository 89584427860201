import MapDraw from 'map/MapDraw';
import { Mobile } from 'types/machine/mobile.type';
import { StorageLocation } from 'types/origin/originLocation.type';

/**
 * 현재위치와 관련된 상태값을 변경한다.
 * @param originLocation
 */
export const changeOriginMapDrawValue = (originLocation: StorageLocation) => {
  // 기기 위치
  MapDraw.mobileMapPosition.coordinate = originLocation.position;
  // 기기 층
  MapDraw.originFloorId = originLocation.floorId;
  // 포인트
  MapDraw.originPointId = originLocation.pointId;
  // 빌딩
  MapDraw.originBuildingId = originLocation.buildingId;
};

/**
 * 지도에 필요한 설정값을 적용한다.
 * @param {Mobile} machineMobile
 */
export const changeMapDrawMobileMapPosition = (machineMobile: Mobile) => {
  MapDraw.mobileMapPosition.centerPositionX = Number(machineMobile.centerPositionX);
  MapDraw.mobileMapPosition.centerPositionY = Number(machineMobile.centerPositionY);
  MapDraw.mobileMapPosition.rotation = Number(machineMobile.rotation);
  MapDraw.mobileMapPosition.zoom = Number(machineMobile.zoom);
  MapDraw.mobileMapPosition.mapMinZoom = Number(machineMobile.mapMinZoom);
  MapDraw.mobileMapPosition.mapMaxZoom = Number(machineMobile.mapMaxZoom);
};
