/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prefer-destructuring */
import NAVIGATION_WHITE from 'assets/icon/navigation-white.svg';
import DisabledScreen from 'components/common/screen/disable/DisabledScreen';
import { LOCAL_STORAGE_MOTION_PERMISSION_KEY } from 'data/localStorageKey';
import useAttachMetric from 'hooks/api/metric/useAttachMetric';
import { t } from 'i18next';
import MapDraw from 'map/MapDraw';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useMapActionStore from 'store/action';
import useColorSetStore from 'store/colorSet';
import useLoadingStore from 'store/loading';
import useMachineStore from 'store/machine';
import useModalStore from 'store/modal';
import useNaviStore from 'store/navi';
import useTenantStore from 'store/tenant';
import useTrackingStore from 'store/tracking';
import useTransportStore from 'store/transport';
import { PathType } from 'types/common/path.type';
import { PermissionType } from 'types/common/permission.type';
import { errorConsole } from 'util/common/console';
import { requestDeviceMotionPermission } from 'util/permission/requestDeviceMotionPermission';
import useDomainStore from 'store/domain';
import styles from '../MapActionButton.module.scss';

type MapActionButtonProps = {
  closePopup: () => void;
};

const MapTrackingButton = ({ closePopup }: MapActionButtonProps) => {
  const transportStore = useTransportStore();
  const mapActionStore = useMapActionStore();
  const tenantStore = useTenantStore();
  const themeStore = useColorSetStore();
  const machineStore = useMachineStore();
  const loadingStore = useLoadingStore();
  const modalStore = useModalStore();
  const naviStore = useNaviStore();
  const trackingStore = useTrackingStore();
  const domainStore = useDomainStore();
  const { clientPath } = useParams();

  const navigate = useNavigate();
  const { search: queryParams, pathname } = useLocation();
  const attachMetricManager = useAttachMetric();

  // 모션 동의 여부
  const localStorageMotionPermission = localStorage.getItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY);
  const isMotionPermissionGranted =
    localStorageMotionPermission && (localStorageMotionPermission as PermissionType) === 'granted';

  /**
   * 모의보행을 시작한다
   * 길찾기 시작 팝업(출발, 도착)을 노출한다
   */
  const onClickNavigate = async () => {
    if (!tenantStore.currentTenant || !machineStore.machine) {
      return;
    }

    /**
     * 기기 모션에 대해 동의를 얻었을 경우 indoor tracking 기능을 실행한다
     */
    try {
      loadingStore.setActionLoading(true);

      if (isMotionPermissionGranted) {
        // ! 지도 회전 제거
        // trackingStore.setTrackingType('ON');
        trackingStore.setTrackingType('FIXED');
      } else {
        trackingStore.setTrackingType('OFF');
      }

      /**
       * 기기 모션에 대해 동의를 얻는다
       */
      const permission = await requestDeviceMotionPermission();

      if (permission === 'granted') {
        await attachMetricManager.postDeviceMotionAttached(true);
        trackingStore.setTrackingType('FIXED');
      } else {
        trackingStore.setTrackingType('OFF');
        await attachMetricManager.postDeviceMotionAttached(false);
      }

      mapActionStore.setMapAction('indoorTracking', true);

      if (pathname !== `/${PathType.floor}`) {
        loadingStore.setLoadingMap(true);
      }

      // 팝업을 닫는다
      closePopup();
      modalStore.setOpenTenantListModal(false);

      moveToFloorPage();

      /**
       * 이동수단을 선택해야 하는 경우
       *
       * 다른 건물 혹은 다른 층으로 길찾기 하는 경우 (복층 길찾기)
       * 층이 끊어져있다면 (가산퍼블릭몰처럼 추천경로에서 층을 비교했을 때 층이 달라진다면 끊어져있다고 간주한다)
       * 이동수단을 선택한다.
       */
      const multiFloor =
        (tenantStore.currentTenant && tenantStore.currentTenant.content.floorId) !== MapDraw.originFloorId;

      const findDiffFloor = naviStore.naviRoutes.recommendation?.locations.find(
        (location: any) => location.floorId !== tenantStore.currentTenant?.content.floorId,
      );

      if (multiFloor || findDiffFloor) {
        mapActionStore.setMapAction('transport', true);
        loadingStore.setActionLoading(false);
        return;
      }

      /**
       * 같은 층으로 길찾기하거나 (단층 길찾기)
       * 층이 끊어져 있지 않는 경우 인도어트래킹을 실행한다.
       */
      mapActionStore.setMapAction('indoorTracking', true);

      if (pathname !== `/${PathType.floor}`) {
        loadingStore.setLoadingMap(true);
      }

      modalStore.setOpenNaviModal(true);
      loadingStore.setActionLoading(false);
    } catch (error) {
      loadingStore.setActionLoading(false);
      errorConsole('Indoor Tracking에 실패했습니다.', error);
    }
  };

  /**
   * 층별안내로 이동한다
   */
  function moveToFloorPage() {
    if (pathname !== `/${PathType.floor}`) {
      // domain type 에 따른 분기
      if (domainStore.domainType === 'WEB') {
        navigate(`/web/${clientPath}/${PathType.floor}`);
      } else {
        navigate(`/${PathType.floor}${queryParams}`);
      }
    }
  }

  return (
    <>
      <button
        style={{ background: themeStore.main }}
        type='button'
        disabled={transportStore.isLoadingTransport}
        onClick={onClickNavigate}
        className={styles.wrapper}
      >
        <div className={styles.icon}>
          <img src={NAVIGATION_WHITE} alt='icon' />
        </div>
        <div>{t(`button.navigation`)}</div>
      </button>

      {transportStore.isLoadingTransport && <DisabledScreen />}
    </>
  );
};
export default MapTrackingButton;
