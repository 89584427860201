import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './Layout.module.scss';
import MenuBar from './menu-bar/MenuBar';

const Layout = () => {
  const location = useLocation();

  return (
    <div className={styles.layout}>
      <Outlet /> {/* 중첩된 Route가 여기에 렌더링됨 */}
      {location.pathname !== '/error' && <MenuBar />}
    </div>
  );
};
export default Layout;
