/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
import ButtonLoader from 'components/common/loader/button/ButtonLoader';
import ModalBottomSheetLayout from 'components/popup/layout/bottom-sheet/ModalBottomSheetLayout';
import MapNavigationButton from 'components/popup/tenant/button/map-action/MapNavigationButton';
import { LOCAL_STORAGE_PARKING_LOCATION_KEY } from 'data/localStorageKey';
import useCheckValidRoute from 'hooks/map/check/useCheckValidRoute';
import useMapRoute from 'hooks/map/event/useMapRoute';
import { t } from 'i18next';
import { drawMarker } from 'map/control/marker';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useNaviStore from 'store/navi';
import useTenantStore from 'store/tenant';
import useTrackingStore from 'store/tracking';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { extractParams } from 'util/params/join/extract/extractParams';
import ParkingLocationInfo from '../common/parking-location-info/ParkingLocationInfo';
import styles from './NaviToMyParkingLocationPopup.module.scss';

type Props = {
  isOpenPopup: boolean;
  onClosePopup: () => void;
};

const NaviToMyParkingLocationPopup = (props: Props) => {
  // hooks
  const mapRouteManager = useMapRoute();
  const { checkValidRoute, routeLoading, notAvailableNavi } = useCheckValidRoute();
  const location = useLocation();

  // variables
  const storageParkingLocation = localStorage.getItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
  const myParkingLocation: TenantWithPointId = storageParkingLocation ? JSON.parse(storageParkingLocation || '') : '';
  const originTenantId: string | undefined = extractParams(location.search, 'originTenantId');

  // TODO: 기존 URL 정리 후 제거
  const tenantIdParam: string | undefined = extractParams(location.search, 'tenantId');

  // store
  const naviStore = useNaviStore();
  const tenantStore = useTenantStore();
  const trackingStore = useTrackingStore();

  /**
   * 길안내를 위해 출발지와 도착지 설정한다
   *
   * 저장된 내 주차위치까지 길찾기를 위한 출발지와 도착지를 설정
   * 저장된 주차위치가 없을 경우 실행하지 않는다
   */
  const handleOriginAndDestination = async (tenantId: string) => {
    if (!tenantId) {
      return;
    }

    // 출발지 설정
    await mapRouteManager.changeOriginLocation(tenantId);

    // 도착지 설정 및 층 변경
    const result = await mapRouteManager.showTenantLocation(myParkingLocation.id, tenantStore.rawTenants);
    await drawMarker('parking', result?.floorId || '', result?.position || { x: 0, y: 0 });

    // 길찾기 유효성 검사 및 경로 탐색
    await checkValidRoute(myParkingLocation);
  };

  // 길안내 가능한지 체크
  useEffect(() => {
    if (originTenantId) {
      handleOriginAndDestination(originTenantId);
    }
  }, [originTenantId, tenantStore.rawTenants]);

  /**
   * TODO: 기존 URL 정리 후 제거
   */
  // 길안내 가능한지 체크
  useEffect(() => {
    if (tenantIdParam) {
      handleOriginAndDestination(tenantIdParam);
    }
  }, [tenantIdParam, tenantStore.rawTenants]);

  useEffect(() => {
    naviStore.setNaviDestIconUrl('/assets/icon/arrive-green.png');
    naviStore.setNaviDestIconSize({ width: 37, height: 52 });
  }, []);

  return (
    <ModalBottomSheetLayout
      disableOutside={trackingStore.isOpenPermissionAlert}
      outsideClose
      {...props}
      dim={false}
      zIndex={30}
    >
      <div className={styles.popup}>
        <div className={styles.content_wrapper}>
          <pre className={styles.message}>{t(`popup.naviToMyParkingLocation`)}</pre>

          <ParkingLocationInfo parkingLocation={myParkingLocation} />
        </div>

        <div className={styles.button_wrapper}>
          {/* 길찾기 버튼 */}
          {routeLoading ? (
            <ButtonLoader />
          ) : !notAvailableNavi ? (
            <MapNavigationButton parkingNavi buttonText={t(`button.navigation`)} closePopup={props.onClosePopup} />
          ) : (
            <> </>
          )}
        </div>
      </div>
    </ModalBottomSheetLayout>
  );
};
export default NaviToMyParkingLocationPopup;
