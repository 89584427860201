import AllSvg from 'components/common/icon/all/AllSvg';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import useColorSetStore from 'store/colorSet';
import { PathType } from 'types/common/path.type';
import { joinParams } from 'util/params/join/joinParams';
import useDomainStore from 'store/domain';
import styles from './AllButton.module.scss';

const AllButton = () => {
  // hook
  const location = useLocation();
  const { t } = useTranslation();
  const { clientPath } = useParams();

  // store
  const colorSetStore = useColorSetStore();
  const domainStore = useDomainStore();

  // WEB 접속시 Active check
  const webIsActiveCheck = () => {
    if (domainStore.domainType === 'WEB') {
      const parts = location.pathname.split('/').filter(Boolean);
      const path = parts[parts.length - 1];
      if (path === PathType.more) {
        return true;
      }
      return false;
    }
    return false;
  };

  const isActive = location.pathname === `/${PathType.more}` || webIsActiveCheck();

  const background = isActive ? colorSetStore.gnb.bg.active : colorSetStore.gnb.bg.inactive;
  const color = isActive ? colorSetStore.gnb.text.active : colorSetStore.text.title;

  // domain type 에 따른 분기
  const setTo = () => {
    if (domainStore.domainType === 'WEB') {
      return `/web/${clientPath}/${PathType.more}`;
    }
    if (domainStore.domainType === 'MOBILE') {
      return `/${PathType.more}?${joinParams(location.search)}`;
    }
    return `/${PathType.error}`;
  };

  return (
    <Link
      style={{
        background,
      }}
      className={styles.container}
      to={setTo()}
    >
      <div className={styles.wrapper}>
        <AllSvg color={color} />

        <div
          style={{
            color,
          }}
          className={styles.text}
        >
          {t('menu.more')}
        </div>
      </div>
    </Link>
  );
};

export default AllButton;
