import InfoGroup from 'components/common/info/group/basic/InfoGroup';
import { ICONS } from 'constants/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useLanguageStore from 'store/languages';
import useTenantStore from 'store/tenant';
import { joinParams } from 'util/params/join/joinParams';
import useDomainStore from 'store/domain';
import styles from './DetailTenantPopupHeader.module.scss';

type DetailTenantPopupHeaderProps = {
  closeModal: () => void;
};

const DetailTenantPopupHeader = ({ closeModal: closeDetailPopup }: DetailTenantPopupHeaderProps) => {
  // store
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();
  const domainStore = useDomainStore();

  // hook
  const location = useLocation();
  const navigate = useNavigate();
  const { clientPath } = useParams();

  if (!tenantStore.currentTenant) {
    return <> </>;
  }

  const onClickClose = () => {
    closeDetailPopup();
    if (domainStore.domainType === 'WEB') {
      const parts = location.pathname.split('/').filter(Boolean);
      const path = parts[parts.length - 1];
      navigate(`/web/${clientPath}/${path}`);
    }
    if (domainStore.domainType === 'MOBILE') {
      navigate(`${location.pathname}?${joinParams(location.search)}`);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header_text_row}>
        <div className={styles.header_text_wrapper}>
          <div className={styles.title}>{tenantStore.currentTenant?.name[langStore.currentLang]}</div>
          <InfoGroup />
        </div>
        <div onClick={onClickClose}>
          <img src={ICONS.CLOSE_BLACK} alt='상세 팝업 닫기 버튼' />
        </div>
      </div>
    </div>
  );
};
export default DetailTenantPopupHeader;
