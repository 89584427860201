import useAttachMetric from 'hooks/api/metric/useAttachMetric';
import { requestDeviceMotionPermission } from 'util/permission/requestDeviceMotionPermission';
import useMapDirection from './useMapDirection';

const useMapMotionPermission = () => {
  const { onDirectionTracking, fixCurrentRotation, offDirectionTracking } = useMapDirection();
  const attachMetricManager = useAttachMetric();

  const onRequestMotionPermission = async () => {
    const permission = await requestDeviceMotionPermission();

    if (permission === 'denied') {
      await offDirectionTracking();
      await attachMetricManager.postDeviceMotionAttached(false);
      return;
    }

    // ! 지도 회전 제거
    // await onDirectionTracking();
    await fixCurrentRotation();
    await attachMetricManager.postDeviceMotionAttached(true);
  };

  return { onRequestMotionPermission };
};
export default useMapMotionPermission;
