export enum ApproachType {
  QR = 'QR',
  BOOTH = 'BOOTH',
  KAKAO = 'KAKAO',
}

export type ApproachMetric = {
  uuid: string;
  pointId: string;
  tenantId: string;
  buildingId?: string;
  poiContentId?: string;
  type: ApproachType;
  boothNumber?: string; // booth type 일 경우 부스번호 필수
  timestamp: number;
};
