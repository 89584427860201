import { LOCAL_STORAGE_BOOKMARK_KEY } from 'data/localStorageKey';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import useBookmarkStore from 'store/bookmark';
import usePopupStore from 'store/popup';
import useTenantStore from 'store/tenant';
import useTimerStore from 'store/timer';
import { TenantWithPointId } from 'types/tenant/tenant.type';

/**
 * 즐겨찾기 기능 관련 hook
 */
const useBookmark = () => {
  // store
  const tenantStore = useTenantStore();
  const bookmarkStore = useBookmarkStore();
  const popupStore = usePopupStore();
  const timerStore = useTimerStore();

  // variable
  const storageBookmarkList = localStorage.getItem(LOCAL_STORAGE_BOOKMARK_KEY);

  // state
  const [isActiveBookmark, setIsActiveBookmark] = useState<boolean>(false);

  /**
   * local storage에 저장되어 있는 북마크 리스트와 현재 선택된 tenant의 매칭 여부에 따라 즐겨찾기 버튼 active 상태 전환
   * @function isTenantBookmarkStatusByStorage
   * @returns {void}
   */
  const isTenantBookmarkStatusByStorage = useCallback((): void => {
    if (!tenantStore.currentTenant) return;
    const bookmarks = JSON.parse(storageBookmarkList || '[]');
    const isBookmarked = bookmarks.some((item: TenantWithPointId) => item.id === tenantStore.currentTenant?.id);
    setIsActiveBookmark(isBookmarked);
  }, [storageBookmarkList, tenantStore.currentTenant]);

  /**
   * 즐겨찾기 추가 또는 해제 시 toast 팝업 show/hide (3초 후 닫기 실행)
   * @function closeToastAfterSeconds
   * @returns {void}
   */
  const closeToastAfterSeconds = (message: string): void => {
    popupStore.setOpenToastPopup(true);
    popupStore.setToastPopupMessage(message);
    timerStore.closeToastPopupAfterSeconds(() => popupStore.setOpenToastPopup(false));
  };

  /**
   * 즐겨찾기 버튼 클릭 이벤트 핸들러
   * @function onClickBookmark
   * @returns {void}
   */
  const onClickBookmark = (): void => {
    if (!tenantStore.currentTenant) return;
    const bookmarks = JSON.parse(storageBookmarkList || '[]');
    const index = bookmarks.findIndex((item: TenantWithPointId) => item.id === tenantStore.currentTenant?.id);

    if (isActiveBookmark) {
      if (index > -1) {
        bookmarks.splice(index, 1); // Remove the tenant from bookmarks
        setIsActiveBookmark(false);
        closeToastAfterSeconds(`${t(`bookmark.clearMessage`)}`);
      }
    } else if (index === -1) {
      bookmarks.push(tenantStore.currentTenant); // Add the tenant to bookmarks
      setIsActiveBookmark(true);
      closeToastAfterSeconds(`${t(`bookmark.saveMessage`)}`);
    }
    localStorage.setItem(LOCAL_STORAGE_BOOKMARK_KEY, JSON.stringify(bookmarks));
    // 북마크 목록 새로고침
    bookmarkStore.setBookmarkRefresh(true);
  };

  /**
   * useEffect, isTenantBookmarkStatusByStorage 함수를 호출하여 초기 bookmark active 상태 정의
   */
  useEffect(() => {
    isTenantBookmarkStatusByStorage();
  }, [isTenantBookmarkStatusByStorage]);

  return { onClickBookmark, isActiveBookmark };
};
export default useBookmark;
