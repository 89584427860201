import { create } from 'zustand';

type PopupStore = {
  openErrorPopup: boolean;
  setOpenErrorPopup: (openSimplePopup: boolean) => void;

  openFloorChangedPopup: boolean;
  setOpenFloorChangedPopup: (openFloorChangedPopup: boolean) => void;

  // 전역적으로 사용하는 토스트 팝업
  openToastPopup: boolean;
  setOpenToastPopup: (openToastPopup: boolean) => void;
  toastMessage: string;
  setToastPopupMessage: (toastMessage: string) => void;
};

const usePopupStore = create<PopupStore>((set, get) => ({
  openErrorPopup: false,
  setOpenErrorPopup(openErrorPopup: boolean) {
    set({
      openErrorPopup,
    });
  },

  openFloorChangedPopup: false,
  setOpenFloorChangedPopup(openFloorChangedPopup: boolean) {
    set({
      openFloorChangedPopup,
    });
  },

  // 전역적으로 사용하는 토스트 팝업
  openToastPopup: false,
  setOpenToastPopup(openToastPopup: boolean) {
    set({
      openToastPopup,
    });
  },

  toastMessage: '',
  setToastPopupMessage(toastMessage: string) {
    set({
      toastMessage,
    });
  },
}));

export default usePopupStore;
