/* eslint-disable no-console */

import { addBuilding } from 'map/control/building';
import { changeFloor } from 'map/control/floor';
import { showLocation } from 'map/control/location';
import { findObject } from 'map/control/object';
import { findPoi } from 'map/control/poi';
import { useEffect } from 'react';
import useMapActionStore from 'store/action';
import useCategoryGroupStore from 'store/categoryGroup';
import useFloorStore from 'store/floor';
import useLoadingStore from 'store/loading';
import useMachineStore from 'store/machine';
import usePointStore from 'store/point';
import useTenantStore from 'store/tenant';
import useTrackingStore from 'store/tracking';
import { CategoryFeature, CategoryGroupWithTenants } from 'types/category/category.type';
import { Floor } from 'types/floor/floor.type';
import { Point } from 'types/point/point.type';
import { changeMapPointSetting } from 'util/map-draw/changeMapSetting';

// 위치 확인
const useMapLocation = () => {
  const tenantStore = useTenantStore();
  const floorStore = useFloorStore();
  const pointStore = usePointStore();
  const categoryStore = useCategoryGroupStore();
  const loadingStore = useLoadingStore();
  const machineStore = useMachineStore();
  const mapActionStore = useMapActionStore();
  const trackingStore = useTrackingStore();

  // 층 목록 변경
  const handleFloors = (pointId: string) => {
    const findFloors: Floor[] | undefined = floorStore.pointFloorsMap?.get(pointId);

    if (findFloors) {
      floorStore.setFloors(findFloors);
    }
  };

  // 선택된 point 변경
  const handleSelectedPoint = (pointId: string) => {
    const findPoint: Point | undefined = pointStore.pointsMap?.get(pointId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);

      // 카메라 설정 및 센터값 변경
      changeMapPointSetting(findPoint);
    }
  };

  // 카테고리 리스트 변경
  const handlePointCategories = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList(CategoryFeature.mapFacility, findCategories);
      }
    }
  };

  // 위치확인
  const triggerLocation = async () => {
    trackingStore.setOpenPermissionAlert(false);

    if (!tenantStore.currentTenant) {
      return;
    }

    const { buildingId, floorId, objectId } = tenantStore.currentTenant.content;
    const { pointId } = tenantStore.currentTenant;

    floorStore.setCurrentFloorId(floorId);
    handleFloors(pointId);

    handleSelectedPoint(pointId);
    handlePointCategories(pointId);

    const machinePointId = machineStore.machine?.point.id || '';

    // 지도 변경
    if (pointId === machinePointId) {
      await changeFloor(floorId);
    } else {
      await addBuilding(buildingId, floorId);
    }

    // objectId 가 있을 경우 -> object 로 위치 확인
    if (objectId) {
      if (loadingStore.hasMapData) {
        const object = findObject({ id: objectId });
        if (object) {
          await showLocation(floorId, object.position);
          return;
        }
      }
      return;
    }

    // objectId 가 없을 경우 -> poiId 로 위치 확인
    const poi = findPoi({
      id: tenantStore.currentTenant?.content.poiId || '',
    });
    if (poi) {
      await showLocation(floorId, poi.position);
    }
  };

  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      if (mapActionStore.actions.location) {
        triggerLocation();
      }
    }
  }, [mapActionStore.actions.location, loadingStore.isLoadingMap]);

  return {
    triggerLocation,
  };
};
export default useMapLocation;
