import { IMAGES } from 'constants/images';
import styles from './BookmarkShortcutButton.module.scss';

type Props = {
  onClick: () => void;
};

const BookmarkShortcutButton = ({ onClick }: Props) => {
  return (
    <div onClick={onClick} className={styles.bookmark_shortcut_button}>
      <img src={IMAGES.BOOKMARK_SHORTCUT} alt='bookmark' />
    </div>
  );
};
export default BookmarkShortcutButton;
