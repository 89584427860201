import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PathType } from 'types/common/path.type';
import { PathQueryParam, TypeQueryParam } from 'types/common/queryParam.type';
import { extractParams, extractPath, extractUrlDomainType } from 'util/params/join/extract/extractParams';

const useNavigateByUrlQueryString = () => {
  // hook
  const location = useLocation();
  const navigate = useNavigate();

  // var
  const typeParam: TypeQueryParam | undefined = extractParams(location.search, 'type');

  /**
   * ! type query string 이 있을 경우 무조건 층별안내 페이지로 라우팅한다.
   */
  useEffect(() => {
    if (typeParam) {
      const domainType = extractUrlDomainType(location);
      if (domainType === 'WEB') {
        const clientPath: string = extractPath(location.pathname);
        navigate(`/web/${clientPath}/${PathType.floor}${location.search}`);
      }
      if (domainType === 'MOBILE') {
        navigate(`/${PathType.floor}${location.search}`);
      }
    }
  }, [typeParam]);

  // TODO: 기존 URL 정리 후 제거
  const pathParam: PathQueryParam | undefined = extractParams(location.search, 'path');
  useEffect(() => {
    if (pathParam) {
      navigate(`/${PathType.floor}${location.search}`);
    }
  }, [pathParam]);
};
export default useNavigateByUrlQueryString;
