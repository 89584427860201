/* eslint-disable no-nested-ternary */
import { Link, useLocation, useParams } from 'react-router-dom';
import useColorSetStore from 'store/colorSet';
import useMachineStore from 'store/machine';
import usePopupStore from 'store/popup';
import { MainMenu } from 'types/common/menu.type';
import { PathType } from 'types/common/path.type';
import { joinParams } from 'util/params/join/joinParams';
import useDomainStore from 'store/domain';
import styles from './HomeButton.module.scss';
import Home from './home/Home';

type Props = {
  menu: MainMenu;
};

const HomeButton = ({ menu }: Props) => {
  // hook
  const location = useLocation();
  const { clientPath } = useParams();

  // store
  const popupStore = usePopupStore();
  const machineStore = useMachineStore();
  const colorSetStore = useColorSetStore();
  const domainStore = useDomainStore();

  const handleClickMenu = () => {
    popupStore.setOpenToastPopup(false);
  };

  // WEB 접속시 Active check
  const webIsActiveCheck = () => {
    if (domainStore.domainType === 'WEB') {
      const parts = location.pathname.split('/').filter(Boolean);
      const clientPathId = parts[parts.length - 1];
      if (clientPathId === clientPath) {
        return true;
      }
      return false;
    }
    return false;
  };

  const isActive = location.pathname === PathType.main || location.pathname === PathType.root || webIsActiveCheck();

  const background = isActive ? colorSetStore.gnb.bg.active : colorSetStore.gnb.bg.inactive;

  // domain type 에 따른 분기
  const setTo = () => {
    if (domainStore.domainType === 'WEB') {
      return `/web/${clientPath}`;
    }
    return `/main?${joinParams(location.search)}`;
  };

  return (
    <Link
      className={styles.main}
      style={{
        background,
        borderRight: `1px solid ${colorSetStore.text.title}`,
      }}
      onClick={handleClickMenu}
      to={setTo()}
    >
      {
        // active 이고 로고 있을 경우
        isActive && machineStore.machine?.point.logoUri ? (
          <div className={styles.logo}>
            <img src={machineStore.machine.point.logoUri} alt='logo' />
          </div>
        ) : (
          // 나머지 경우
          // 1. 로고가 없거나
          // 2. active 가 아니거나
          // 3. 아이콘을 사용하거나 안하거나
          <Home isActive={isActive} menu={menu} />
        )
      }
    </Link>
  );
};

export default HomeButton;
