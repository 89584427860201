import GoUpButton from 'components/common/button/go-up/GoUpButton';
import NoTenant from 'components/common/no-tenant/NoTenant';
import DetailTenantPopup from 'components/popup/tenant/detail/DetailTenantPopup';
import SimpleTenantPopup from 'components/popup/tenant/simple/SimpleTenantPopup';
import { PAGE_SIZE } from 'data/commons';
import useUsageMetric from 'hooks/api/metric/useUsageMetric';
import useGoTopButton from 'hooks/go-to-top/useGoTopButton';
import { Fragment, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Transition } from 'react-transition-group';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import useMachineStore from 'store/machine';
import usePopupStore from 'store/popup';
import useTenantStore from 'store/tenant';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import useKeywordStore from 'store/keyword';
import styles from './TenantList.module.scss';
import TenantItem from './item/TenantItem';
import KeywordContainer from './keywordContainer/KeywordContainer';

const TenantList = () => {
  // state
  const [openTenantPopup, setOpenTenantPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // store
  const tenantStore = useTenantStore();
  const popupStore = usePopupStore();
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();
  const machineStore = useMachineStore();
  const keywordStore = useKeywordStore();

  // ref
  const scrollRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  // hooks
  const { showGoButton } = useGoTopButton({ listRef });
  const { postTenantUsage } = useUsageMetric();

  // index
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;

  /**
   * @desc 스크롤 시 실행 함수
   */
  const onLoadMore = () => {
    setCurrentPage(prev => prev + 1);
  };

  const handleClickItem = async (tenant: TenantWithPointId) => {
    if (!machineStore.machine) {
      return;
    }

    // 매장 사용량 수집
    await postTenantUsage(machineStore.machine.point.id, tenant.id, 'DETAIL_OF_TENANT', machineStore.machine.id);

    popupStore.setOpenToastPopup(false);
    tenantStore.setCurrentTenant(tenant);

    setOpenTenantPopup(true);
  };

  const closeTenantPopup = () => {
    setOpenTenantPopup(false);
  };

  const hasTenants = tenantStore.filteredTenants && tenantStore.filteredTenants.length > 0;
  const hasKeyword = keywordStore.filterContent && keywordStore.filterContent.length > 0;

  const initTenants = () => {
    tenantStore.setFilteredTenants(tenantStore.searchTenants, langStore.currentLang);
  };

  const initKeyword = () => {
    keywordStore.setFilterKeyword(keywordStore.content, keywordStore.keyword.text);
  };

  useEffect(() => {
    initTenants();
    initKeyword();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.list}
          ref={listRef}
          style={{
            background: colorSetStore.list.bg,
          }}
        >
          <div ref={scrollRef} />

          {hasKeyword && <KeywordContainer />}

          {hasTenants && (
            <InfiniteScroll
              pageStart={0}
              loadMore={onLoadMore}
              hasMore={currentPage * PAGE_SIZE < tenantStore.filteredTenants.length}
              // loader={
              //   <div className='loader' key='infinite-scroll-loader'>
              //     Loading ...
              //   </div>
              // }
              useWindow={false}
            >
              {tenantStore.filteredTenants?.slice(0, endIndex).map(tenant => (
                <Fragment key={tenant.id}>
                  <TenantItem handleClickItem={handleClickItem} tenant={tenant} type='search' />
                </Fragment>
              ))}
            </InfiniteScroll>
          )}
        </div>

        {/* 위로가기 버튼 */}
        <GoUpButton scrollRef={scrollRef} isShow={showGoButton} overButton />
      </div>

      {!hasTenants && <NoTenant />}

      {/* 테넌트 팝업 */}
      <Transition timeout={500} in={openTenantPopup} unmountOnExit>
        {tenantStore.currentTenant?.popupType === 'SMALL' ? (
          <SimpleTenantPopup closeModal={closeTenantPopup} isOpenPopup={openTenantPopup} />
        ) : (
          <DetailTenantPopup closeModal={closeTenantPopup} isOpenPopup={openTenantPopup} />
        )}
      </Transition>
    </>
  );
};
export default TenantList;
