import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import useCommonStore from 'store/common';
import { errorConsole } from 'util/common/console';

/**
 * 모바일 기기 모션 접근 허용 여부 수집
 */
const useAttachMetric = () => {
  // hook
  const apiManager = useAxios();
  // store
  const commonStore = useCommonStore();

  /**
   * 모바일 모션 접근 허용 여부 통계 수집
   * @param {boolean} isGranted 허용 여부
   */
  const postDeviceMotionAttached = async (isGranted: boolean) => {
    /**
     * uuid가 없거나 이미 attached 여부에 대해 보냈다면 두번은 보내지 않는다
     */
    if (!commonStore.appUuid || commonStore.sendGranted) {
      return;
    }

    try {
      await apiManager.api.post(EndPoint.deviceMotionAttachedV2, {
        uuid: commonStore.appUuid,
        attached: isGranted,
      });

      commonStore.setSendGranted(true);
    } catch (error) {
      errorConsole(`기기 모션 접근 허용 여부 api post 에러:`, error);
    }
  };

  return { postDeviceMotionAttached };
};
export default useAttachMetric;
