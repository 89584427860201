import axios, { AxiosError, AxiosInstance } from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useDomainStore from 'store/domain';
import { PathType } from 'types/common/path.type';
import { joinParams } from 'util/params/join/joinParams';

interface CustomInstance extends AxiosInstance {
  get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
  post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
  put<T>(...params: Parameters<AxiosInstance['put']>): Promise<T>;
  delete<T>(...params: Parameters<AxiosInstance['delete']>): Promise<T>;
}

export const useAxios = () => {
  const domainStore = useDomainStore();

  const navigate = useNavigate();
  const location = useLocation();
  const { clientPath } = useParams();

  const api: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': process.env.REACT_APP_API_KEY,
    },
  });

  api.interceptors.request.use(
    async config => {
      return config;
    },
    error => Promise.reject(error),
  );

  api.interceptors.response.use(
    response => {
      return response.data;
    },
    (error: AxiosError) => {
      if (domainStore.domainType === 'WEB') {
        return `/web/${clientPath}/${PathType.error}`;
      }
      if (domainStore.domainType === 'MOBILE') {
        navigate(`/${PathType.error}?${joinParams(location.search)}`);
      }
      throw error;
    },
  );

  return { api };
};
