import ArrowLeftSvg from 'components/common/icon/arrow/left/ArrowLeftSvg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useColorSetStore from 'store/colorSet';
import { PathType } from 'types/common/path.type';
import { joinParams } from 'util/params/join/joinParams';
import useDomainStore from 'store/domain';
import styles from './CustomBoardHeader.module.scss';

type Props = {
  title: string;
};

const CustomBoardHeader = ({ title }: Props) => {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { clientPath } = useParams(); // 동적 파라미터 읽기

  // store
  const domainStore = useDomainStore();

  const colorSetStore = useColorSetStore();

  const onClick = () => {
    if (domainStore.domainType === 'WEB') {
      navigate(`/web/${clientPath}/${PathType.more}`);
    }
    if (domainStore.domainType === 'MOBILE') {
      navigate(`/${PathType.more}?${joinParams(location.search)}`);
    }
    return `/${PathType.error}`;
  };

  return (
    <div
      className={styles.header}
      style={{
        background: colorSetStore.header.bg,
        color: colorSetStore.header.text,
      }}
    >
      <div className={styles.arrow} onClick={onClick}>
        <ArrowLeftSvg color={colorSetStore.header.text} />
      </div>
      {title}
    </div>
  );
};

export default CustomBoardHeader;
