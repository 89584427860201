import useFloor from 'hooks/api/floor/useFloor';
import useMapDirection from 'hooks/map/trigger/useMapDirection';
import { resetCamera } from 'map/control/camera';
import { clearMap, clearMyLocation } from 'map/control/common/clear';
import { changeFloor } from 'map/control/floor';
import { Fragment, useRef, useState } from 'react';
import useColorSetStore from 'store/colorSet';
import useFacilityStore from 'store/facility';
import useFloorStore from 'store/floor';
import useLoadingStore from 'store/loading';
import usePointStore from 'store/point';
import usePopupStore from 'store/popup';
import useTrackingStore from 'store/tracking';
import { changeMapPointSetting } from 'util/map-draw/changeMapSetting';
import styles from './FloorList.module.scss';
import FloorItem from './floor-item/FloorItem';
import MyLocationBtn from './my-location/MyLocationBtn';

type FloorListProps = {
  removeNoFacilityToast: () => void;
};

const FloorList = ({ removeNoFacilityToast }: FloorListProps) => {
  const floorStore = useFloorStore();
  const facilityStore = useFacilityStore();
  const popupStore = usePopupStore();
  const colorSetStore = useColorSetStore();
  const pointStore = usePointStore();
  const loadingStore = useLoadingStore();
  const trackingStore = useTrackingStore();

  const { findFloorName } = useFloor();
  const { switchActionByTrackingType } = useMapDirection();

  const scrollRef = useRef<HTMLDivElement>(null);

  const [clickFloorBtn, setClickFloorBtn] = useState(false);

  // 층 이름 변경
  const changeFloorName = (floorId: string) => {
    const floorName = findFloorName(floorId, pointStore.selectedPoint?.id || '');
    floorStore.setFloorName(floorName);
  };

  // 층 선택
  const onClickFloor = async (floorId: string) => {
    // 모의주행 중이라면 X
    if (loadingStore.isNavi) return;

    loadingStore.setFloorChanging(true);
    popupStore.setOpenToastPopup(false);

    setClickFloorBtn(true);

    clearMap();
    clearMyLocation();
    removeNoFacilityToast();

    if (trackingStore.trackingType === 'OFF') {
      resetCamera();
    }

    // 층
    floorStore.setCurrentFloorId(floorId);
    // 층 이름 변경
    changeFloorName(floorId);

    //  편의시설
    facilityStore.resetCurrentFacilityId();

    await changeFloor(floorId);
    await switchActionByTrackingType(trackingStore.trackingType);

    if (pointStore.selectedPoint) {
      // 카메라 설정 및 센터값 변경
      changeMapPointSetting(pointStore.selectedPoint);
    }

    loadingStore.setFloorChanging(false);
  };

  return (
    <div
      className={styles.floor_list_with_location_btn}
      style={{
        background: colorSetStore.header.bg,
      }}
    >
      <div className={styles.floors} ref={scrollRef}>
        {[...floorStore.floors].reverse().map(floor => {
          const isActive = floorStore.currentFloorId === floor.id;

          return (
            <Fragment key={floor.id}>
              <FloorItem
                clickFloorBtn={clickFloorBtn}
                scrollRef={scrollRef}
                isActive={isActive}
                floor={floor}
                handleClickFloor={onClickFloor}
                currentFloorId={floorStore.currentFloorId}
              />
            </Fragment>
          );
        })}
      </div>
      <MyLocationBtn setClickFloorBtn={setClickFloorBtn} />
    </div>
  );
};
export default FloorList;
