import { ME_DEFAULT, ME_FIXED, ME_TRACKING } from 'data/image';
import { showMyLocationNoAni } from 'map/control/myLocation';
import { PositionNum } from 'map/type/position.type';
import { TrackingType } from 'store/tracking';

/**
 * tracking type 에 따라 다른 my location 을 그린다
 * @param {TrackingType} trackingType
 */
export const drawMyLocationByTrackingType = async (trackingType: TrackingType, coordinate: PositionNum) => {
  // ! 지도 회전 제거
  // if (trackingType === 'ON') {
  //   await showMyLocationNoAni(ME_TRACKING, coordinate);
  // } else

  if (trackingType === 'FIXED') {
    await showMyLocationNoAni(ME_FIXED, coordinate);
  } else {
    await showMyLocationNoAni(ME_DEFAULT, coordinate);
  }
};
