import React from 'react';
import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import useKeywordStore from 'store/keyword';
import { ApiResponse } from 'types/api/response';
import { errorConsole } from 'util/common/console';
import { Content } from 'types/tenant/keyword.type';

const useKeyword = () => {
  const { api } = useAxios();

  // store
  const keywordStore = useKeywordStore();

  /**
   * @desc 키워드 리스트 조회
   * @param pointId
   */
  const getPoiContentKeyword = async (pointId: string) => {
    try {
      const response = await api.get<ApiResponse<Content[]>>(EndPoint.searchKeyword, {
        params: {
          pointId,
        },
      });

      if (response.data) {
        const keywords = response.data;
        // keyword obj를  fullSearchKeyword : {obj} 로 형태로 가공합니다.
        const result: { [key: string]: Content } = {};
        // eslint-disable-next-line no-return-assign
        keywords.map((keyword: Content) => (result[keyword.fullSearchKeyword] = { ...keyword }));
        keywordStore.setKeywordOfContent(result);
      }
    } catch {
      errorConsole('Keyword를 검색할 수 없습니다.');
    }
  };
  return { getPoiContentKeyword };
};

export default useKeyword;
