/* eslint-disable no-console */
import useUsageMetric from 'hooks/api/metric/useUsageMetric';
import { drawMarker } from 'map/control/marker';
import { PositionNum } from 'map/type/position.type';
import useLanguageStore from 'store/languages';
import useMachineStore from 'store/machine';
import useModalStore from 'store/modal';
import useTenantStore from 'store/tenant';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { logConsole } from 'util/common/console';
import { sortByLangOrder } from 'util/sort/lang-order/sortByLangOrder';

const useMultiContent = () => {
  const tenantStore = useTenantStore();
  const modalStore = useModalStore();
  const langStore = useLanguageStore();
  const machineStore = useMachineStore();

  const { postTenantUsage } = useUsageMetric();

  // content 한 개 일 경우
  const handleSingleContent = (tenant: TenantWithPointId) => {
    logConsole('단일 content !');

    tenantStore.setCurrentTenant(tenant);
  };

  // 다중 contents 일 경우
  const handleMultiContent = (tenants: TenantWithPointId[]) => {
    logConsole('다중 content !');

    // 모든 테넌트 오름차순 정렬
    const sortedTenants: TenantWithPointId[] = tenants.sort((a, b) =>
      sortByLangOrder(a.name[langStore.currentLang], b.name[langStore.currentLang]),
    );

    tenantStore.setCurrentTenant(sortedTenants[0]);
    tenantStore.setCurrentTenantArr(sortedTenants);
    modalStore.setOpenTenantListModal(true);
  };

  // 다중 content, content 처리
  const handlePoiContent = async (tenants: TenantWithPointId[], position: PositionNum) => {
    if (!machineStore.machine) {
      return;
    }

    // 매장 사용량 수집
    await postTenantUsage(machineStore.machine.point.id, tenants[0].id, 'DETAIL_OF_FLOOR', machineStore.machine.id);

    if (tenants.length > 1) {
      // content 여러개일 경우
      modalStore.setOpenTenantModal(false);
      handleMultiContent(tenants);
    } else {
      // content 한 개일 경우
      handleSingleContent(tenants[0]);
      modalStore.setOpenTenantModal(true);
    }

    // 마커
    await drawMarker('default', tenants[0].content.floorId, position);
  };

  return { handlePoiContent };
};
export default useMultiContent;
