import { COLORS } from 'data/color';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import useColorSetStore from 'store/colorSet';
import useLanguageStore from 'store/languages';
import useMenuStore from 'store/menu';
import usePopupStore from 'store/popup';
import { PathType } from 'types/common/path.type';
import { joinParams } from 'util/params/join/joinParams';
import useDomainStore from 'store/domain';
import styles from './MenuButtons.module.scss';
import MenuIcon from './menu-icon/MenuIcon';

const MenuButtons = () => {
  const [currentMenu, setCurrentMenu] = useState<string>('');

  // store
  const menuStore = useMenuStore();
  const colorSetStore = useColorSetStore();
  const popupStore = usePopupStore();
  const langStore = useLanguageStore();
  const domainStore = useDomainStore();

  // hook
  const location = useLocation();
  const { clientPath } = useParams();

  // WEB 접속시 Active check
  const webIsActiveCheck = () => {
    if (domainStore.domainType === 'WEB') {
      const parts = location.pathname.split('/').filter(Boolean);
      const clientPathId = parts[parts.length - 1];
      if (clientPathId === clientPath) {
        return true;
      }
      return false;
    }
    return false;
  };

  const isMainPage = location.pathname === PathType.main || location.pathname === PathType.root || webIsActiveCheck();

  const onClickMenu = _.debounce(() => {
    popupStore.setOpenToastPopup(false);
  }, 1000);

  useEffect(() => {
    if (domainStore.domainType === 'WEB') {
      if (isMainPage) {
        setCurrentMenu('main');
        return;
      }
      const parts = location.pathname.split('/').filter(Boolean);
      const path = parts[parts.length - 1];
      setCurrentMenu(path);
    } else {
      if (isMainPage) {
        setCurrentMenu('main');
        return;
      }
      setCurrentMenu(location.pathname.slice(1));
    }
  }, [location.pathname]);

  // domain type 에 따른 분기
  const setTo = (type: string) => {
    if (domainStore.domainType === 'WEB') {
      return `/web/${clientPath}/${type}`;
    }
    return `/${type}?${joinParams(location.search)}`;
  };

  return (
    <div className={styles.buttons}>
      {menuStore.fixedMenus.map(({ type, name, iconUsed, mainIconUri, customPageId }, index) => {
        const isActive = type === currentMenu;
        const background = isActive ? colorSetStore.gnb.bg.active : colorSetStore.gnb.bg.inactive;
        const color = isActive ? colorSetStore.gnb.text.active : colorSetStore.text.title;

        return (
          <Fragment key={index}>
            <Link
              style={{ background, borderRight: `1px solid ${colorSetStore.text.title}` }}
              onClick={onClickMenu}
              className={styles.button}
              to={setTo(type)}
              state={{ pageId: customPageId }}
            >
              {/* 아이콘 */}
              {iconUsed && (
                <div className={styles.icon}>
                  <MenuIcon iconUri={mainIconUri} iconColor={isActive ? COLORS.WHITE : colorSetStore.text.title} />
                </div>
              )}

              {/* 메뉴 이름 */}
              <div style={{ color }} className={styles.menu_text}>
                {name[langStore.currentLang]}
              </div>
            </Link>
          </Fragment>
        );
      })}
    </div>
  );
};

export default MenuButtons;
