import BookmarkContainer from 'components/bookmark/BookmarkContainer';
import styles from 'pages/Pages.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useDomainStore from 'store/domain';
import { PathType } from 'types/common/path.type';
import { joinParams } from 'util/params/join/joinParams';

const BookmarkPage = () => {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();

  const domainStore = useDomainStore();
  const { clientPath } = useParams(); // 동적 파라미터 읽기

  const onClick = () => {
    if (domainStore.domainType === 'WEB') {
      navigate(`/web/${clientPath}/${PathType.more}`);
    }
    if (domainStore.domainType === 'MOBILE') {
      navigate(`/${PathType.more}?${joinParams(location.search)}`);
    }
  };

  return (
    <div className={`${styles.customMenu} ${styles.open}`}>
      <BookmarkContainer closeBookmarks={onClick} />
    </div>
  );
};
export default BookmarkPage;
