/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
import MapDraw from 'map/MapDraw';
import { PositionNum } from 'map/type/position.type';
import { RouteOption } from 'map/type/route.type';
import { NaviGationSpeedOption } from 'types/machine/workspace.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { warnConsole } from 'util/common/console';
import { findObject } from './object';

// 경로 탐색
export async function findRoute(machinePosition: PositionNum, tenant: TenantWithPointId | null) {
  if (!tenant) {
    return;
  }

  // object 있을 경우 -> objectId 로 길찾기
  if (tenant.content.objectId) {
    // object 정보 탐색
    const findMapObject = findObject({ id: tenant.content.objectId });
    if (!findMapObject) {
      warnConsole(`object 를 찾지 못했습니다.`);
      return;
    }

    // const destinationPosition = convertPositionType(findMapObject);

    const routeOption: RouteOption = {
      origin: {
        position: { x: Number(machinePosition.x), y: Number(machinePosition.y) },
        floorId: MapDraw.originFloorId,
      },

      // opening 을 타려면 objectId 를 사용해야 함
      destination: {
        // position: { x: Math.abs(destinationPosition.x), y: Math.abs(destinationPosition.y) },
        floorId: tenant.content.floorId,
        objectId: tenant.content.objectId,
      },
      type: ['recommendation', 'elevator', 'escalator', 'stairs'],
      waypoints: [],
    };

    const naviResponse = await getRoute(routeOption);

    // logConsole(`출발 좌표 : ${machinePosition.x}, ${machinePosition.y}`);
    // logConsole(`도착지 : `, tenant);
    // logConsole(`도착 object : `, findMapObject);
    // logConsole(`objectId naviResponse : `, naviResponse);

    return naviResponse;
  }

  // object 없을 경우 -> poiId 로 길찾기
  const routeOption: RouteOption = {
    origin: {
      position: { x: Number(machinePosition.x), y: Number(machinePosition.y) },
      floorId: MapDraw.originFloorId,
    },
    destination: {
      poiId: tenant.content.poiId,
      floorId: tenant.content.floorId,
    },
    type: ['recommendation', 'elevator', 'escalator', 'stairs'],
    waypoints: [],
  };

  const naviResponse = await getRoute(routeOption);

  return naviResponse;
}

// 경로 탐색
export async function getRoute(routeOption: RouteOption) {
  const naviResponse = await MapDraw.mapData.getRoute(routeOption);
  return naviResponse;
}

export async function startRouteSimulation(zoom: number, speedRate: number, ease: NaviGationSpeedOption) {
  /**
   * animOption
   */
  await MapDraw.map.routeSimulation.start({
    //  도착지 옵션
    destOption: {
      opacity: 0.8,
      isAnimate: true,
      duration: 1000,
      isRepeat: false,
      isYoyo: false,
    },
    changeFloorDelay: 0,
    speedRate,
    ease,
    removeIcon: true,
    markerOptions: {
      iconUrl: '/assets/icon/na.svg',
      width: 36,
      height: 36,
    },
    enableFloorMotionCSS: true,
    floorMotionDuration: 1500,
    zoom,
  });
}
