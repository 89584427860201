/* eslint-disable prefer-destructuring */
import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import useFloor from 'hooks/api/floor/useFloor';
import usePoint from 'hooks/api/point/usePoint';
import MapDraw from 'map/MapDraw';
import { changeCamera, moveCamera } from 'map/control/camera';
import { clearIndoorTracking, clearMyLocation } from 'map/control/common/clear';
import { changeMapMinMaxZoom } from 'map/control/zoom';
import { Transport } from 'map/type/transport.type';
import { useEffect, useRef } from 'react';
import useMapActionStore from 'store/action';
import useBuildingStore from 'store/building';
import useCategoryGroupStore from 'store/categoryGroup';
import useCommonStore from 'store/common';
import useFloorStore from 'store/floor';
import useLoadingStore from 'store/loading';
import useMachineStore from 'store/machine';
import useNaviStore from 'store/navi';
import usePointStore from 'store/point';
import usePopupStore from 'store/popup';
import useTenantStore from 'store/tenant';
import useTransportStore from 'store/transport';
import { CategoryFeature, CategoryGroupWithTenants } from 'types/category/category.type';
import { Floor } from 'types/floor/floor.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import { errorConsole } from 'util/common/console';
import { changeMapPointSettingWithoutCamera } from 'util/map-draw/changeMapSetting';
import useMapRoute from '../event/useMapRoute';
import useMapFloor from '../floor/useMapFloor';

// 경로안내
const useMapNavigation = () => {
  const transportStore = useTransportStore();
  const mapActionStore = useMapActionStore();
  const tenantStore = useTenantStore();
  const machineStore = useMachineStore();
  const floorStore = useFloorStore();
  const popupStore = usePopupStore();
  const pointStore = usePointStore();
  const categoryStore = useCategoryGroupStore();
  const buildingStore = useBuildingStore();
  const naviStore = useNaviStore();
  const loadingStore = useLoadingStore();
  const commonStore = useCommonStore();

  const { findFloorNameByBuildingId } = useFloor();
  const { changeMapStates } = useMapFloor();
  const { setPointNameByBuildingId } = usePoint();
  const mapRouteManager = useMapRoute();

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // 3초 후 팝업 닫기
  const closePopupAfterSeconds = () => {
    timerRef.current = setTimeout(() => {
      popupStore.setOpenFloorChangedPopup(false);
    }, 3000);
  };

  // 팝업 타이머 제거
  const clearPopupTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    popupStore.setOpenFloorChangedPopup(false);
  };

  // 층 변경 팝업 열기
  const openFloorChangedPopup = () => {
    popupStore.setOpenFloorChangedPopup(true);
    closePopupAfterSeconds();
  };

  // 층 목록 변경
  const changeFloors = (buildingId: string) => {
    const findFloors: Floor[] | undefined = floorStore.buildingFloorsMap?.get(buildingId);

    if (findFloors) {
      floorStore.setFloors(findFloors);
    }
  };

  // 층 이름 변경
  const changeFloorName = (floorId: string, buildingId: string) => {
    const floorName = findFloorNameByBuildingId(floorId, buildingId);

    if (floorName) {
      floorStore.setFloorName(floorName);
    }
  };

  // 카테고리
  const changeCategory = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList(CategoryFeature.mapFacility, findCategories);
      }
    }
  };

  // 포인트
  const changePoint = (buildingId: string) => {
    const findPoint = pointStore.buildingPointsMap?.get(buildingId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);

      // 카테고리
      changeCategory(findPoint.id);
    }
  };

  /**
   * @desc floor-changing 이벤트 발생 시 실행되는 함수
   * @param e floor-changing 이벤트
   */
  const handleFloorChanging = (e: any) => {
    const buildingId = e.detail.floor.next.buildingId;
    const floorId = e.detail.floor.next.id;

    // 빌딩 이름
    setPointNameByBuildingId(buildingId);
    // 빌딩 타입
    buildingStore.setBuildingType(e.detail.floor.next.isOutdoor ? 'OUTDOOR' : 'INDOOR');

    // 층
    floorStore.setCurrentFloorId(floorId);
    // 층 목록
    changeFloors(buildingId);
    // 층 이름
    changeFloorName(floorId, buildingId);

    // 팝업 관리
    openFloorChangedPopup();

    // 포인트
    changePoint(buildingId);
  };

  /**
   * @desc floor-changed 이벤트 발생 시 실행되는 함수
   * @param e floor-changed 이벤트
   */
  const handleFloorChanged = (e: any) => {
    const findPoint = pointStore.buildingPointsMap?.get(e.detail.buildingId);
    if (findPoint) {
      // 카메라 설정 및 센터값 변경
      changeMapPointSettingWithoutCamera(findPoint);
    }
  };

  // 길찾기 시작
  const startNavi = async () => {
    // 모의주행 경로를 그린다

    await mapRouteManager.drawRoute(transportStore.transport as Transport);

    // 모의주행을 시작한다
    await mapRouteManager.startRoute();
  };

  /**
   * @desc navi-complete 이벤트 발생 시 실행되는 함수
   * @param e navi-complete 이벤트
   */
  const handleNaviComplete = () => {
    clearMyLocation();
    clearPopupTimer();

    mapActionStore.resetMapActions();

    naviStore.setNaviDestIconUrl('/assets/icon/arrive.png');
    naviStore.setNaviDestIconSize({ width: 14, height: 20 });

    // 모의주행 종료
    loadingStore.setNavi(false);

    if (!commonStore.hasMachineId) {
      naviStore.setNaviType('INIT');
    } else {
      naviStore.setNaviType('REPLAY');
    }
    // 층 변경 중 발생하는 이벤트 구독 취소
    MapDraw.mapContainer.removeEventListener('floor-changing', handleFloorChanging);
    // 층 변경 후 발생하는 이벤트 구독 취소
    MapDraw.mapContainer.removeEventListener('floor-changed', handleFloorChanged);
    // 모의주행 끝난 후 발생하는 이벤트 구독 취소
    MapDraw.mapContainer.removeEventListener('navi-complete', handleNaviComplete);
  };

  // 길찾기
  const triggerNavigation = async () => {
    try {
      if (!machineStore.machine || !tenantStore.currentTenant) {
        return;
      }

      loadingStore.setActionLoading(true);

      await clearIndoorTracking();

      // 길찾기 아이콘 옵션 설정
      naviStore.setNaviDestIconUrl('/assets/icon/arrive.png');
      naviStore.setNaviDestIconSize({ width: 14, height: 20 });

      // 길찾기 시 기본 floorId 는 machine floor
      let startPointId = machineStore.machine.point.id;
      let startFloorId = machineStore.machine.floor.id;

      const storageOriginLocation: string | null = localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);

      // 저장된 현재위치 있을 경우 현재위치 floorId 로 변경
      if (storageOriginLocation) {
        const origin: StorageLocation = JSON.parse(storageOriginLocation);

        startPointId = origin.pointId;
        startFloorId = origin.floorId;
      }

      // 길찾기 시작 시 상태값 변경
      changeMapStates(startPointId, startFloorId);

      // 첫 기기 세팅과 동일하게 변경
      moveCamera({
        x: MapDraw.mobileMapPosition.centerPositionX,
        y: MapDraw.mobileMapPosition.centerPositionY,
      });

      // 기기 포인트 최소/최대 줌레벨 적용
      changeMapMinMaxZoom(MapDraw.mobileMapPosition.mapMinZoom, MapDraw.mobileMapPosition.mapMaxZoom);

      // 기기 방향각, 줌레벨 적용
      changeCamera({
        rotation: MapDraw.mobileMapPosition.rotation,
        zoom: MapDraw.mobileMapPosition.zoom,
        transition: true,
      });

      // 길찾기 시작
      await startNavi();

      // 층 변경 중 발생하는 이벤트 구독
      MapDraw.mapContainer.addEventListener('floor-changing', handleFloorChanging);
      // 층 변경 후 발생하는 이벤트 구독
      MapDraw.mapContainer.addEventListener('floor-changed', handleFloorChanged);
      // 모의주행 끝난 후 발생하는 이벤트 구독
      MapDraw.mapContainer.addEventListener('navi-complete', handleNaviComplete);

      loadingStore.setActionLoading(false);
    } catch {
      errorConsole(`navigation error!`);
    } finally {
      loadingStore.setActionLoading(false);
    }
  };

  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      if (mapActionStore.actions.navigation) {
        triggerNavigation();
      }
    }
  }, [mapActionStore.actions.navigation, loadingStore.isLoadingMap]);

  return {
    triggerNavigation,
  };
};
export default useMapNavigation;
