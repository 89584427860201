import useMapRoute from 'hooks/map/event/useMapRoute';
import { t } from 'i18next';
import MapDraw from 'map/MapDraw';
import { clearAllMarkers, clearMap, clearMyLocation } from 'map/control/common/clear';
import { Transport } from 'map/type/transport.type';
import OutsideClickHandler from 'react-outside-click-handler';
import useMapActionStore from 'store/action';
import useColorSetStore from 'store/colorSet';
import useCommonStore from 'store/common';
import useFloorStore from 'store/floor';
// import useMachineStore from 'store/machine';
import useModalStore from 'store/modal';
import useNaviStore from 'store/navi';
import useTenantStore from 'store/tenant';
import useTrackingStore from 'store/tracking';
import useTransportStore from 'store/transport';
import { drawMyLocationByTrackingType } from 'util/common/mapCommon';
import styles from './TransportPopup.module.scss';

type TransportPopupProps = {
  closeTransportPopup: () => void;
};

const TransportPopup = ({ closeTransportPopup }: TransportPopupProps) => {
  // store
  const transportStore = useTransportStore();
  const mapActionStore = useMapActionStore();
  const floorStore = useFloorStore();
  const themeStore = useColorSetStore();
  const modalStore = useModalStore();
  const trackingStore = useTrackingStore();
  const commonStore = useCommonStore();
  const tenantStore = useTenantStore();
  const naviStore = useNaviStore();
  // const machineStore = useMachineStore();

  // hook
  const mapRouteManager = useMapRoute();

  const onClick = async (transport: string) => {
    clearAllMarkers();
    closeTransportPopup();

    transportStore.setCurrentTransport(transport);
    floorStore.setCurrentFloorId(MapDraw.originFloorId);

    /**
     *
     * 이동수단을 선택해야 하는 경우
     *
     * 다른 건물 혹은 다른 층으로 길찾기 하는 경우 (복층 길찾기)
     * 층이 끊어져있다면 (가산퍼블릭몰처럼 추천경로에서 층을 비교했을 때 층이 달라진다면 끊어져있다고 간주한다)
     * 이동수단을 선택한다.
     */
    const multiFloor =
      (tenantStore.currentTenant && tenantStore.currentTenant.content.floorId) !== MapDraw.originFloorId;

    const findDiffFloor = naviStore.naviRoutes.recommendation?.locations.find(
      (location: any) => location.floorId !== tenantStore.currentTenant?.content.floorId,
    );

    /**
     * ! (삭제금지)
     * ! 복층 일때만 경로를 그린다
     * ! 단층은 useMapTracking 에서 경로 그린다
     */
    if (multiFloor || findDiffFloor) {
      await mapRouteManager.drawRoute(transport as Transport);
    }

    if (!commonStore.hasMachineId) {
      mapActionStore.setMapAction('navigation', true);
    } else {
      mapActionStore.setMapAction('indoorTracking', true);
      modalStore.setOpenNaviModal(true); // tracking 안내 팝업을 연다
    }
  };

  // useEffect(() => {
  //   return () => {
  //     mapActionStore.resetMapActions();
  //   };
  // }, []);

  return (
    <div className={styles.background}>
      <OutsideClickHandler
        onOutsideClick={async () => {
          mapActionStore.resetMapActions();

          clearMap();
          closeTransportPopup();
          clearMyLocation();
          await drawMyLocationByTrackingType(trackingStore.trackingType, MapDraw.mobileMapPosition.coordinate);
        }}
      >
        <div className={styles.popup}>
          <div className={styles.text}>{t(`popup.transport.desc`)}</div>

          <div className={styles.transports_wrapper}>
            {transportStore.transports?.map(transport => (
              <div
                style={{ background: themeStore.sub }}
                className={styles.transport}
                onClick={onClick.bind(this, transport)}
                key={transport}
              >
                <img className={styles.icon} src={`/assets/icon/transport/${transport}.svg`} alt={transport} />
                <div className={styles.transport_text}>{t(`popup.transport.method.${transport}`)}</div>
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
export default TransportPopup;
